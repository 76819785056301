// 公共方法
export const commonFun = {

  //获取按钮的显示
  getButtonRules: function (fullPath, url) {
    //fullPath  当前页面路径的route.fullPath
    //url 当前页面调用的接口信息
    let buttonShow = {
      add: true,
      delete: true,
      edit: true,
      page: true,
    }
    if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
      let rules = JSON.parse(localStorage.getItem("rules"))
      rules = rules[fullPath]
      if (rules != undefined) {
        for (let key in buttonShow) {
          buttonShow[key] = rules.includes(url[key]) ? true : false
        }
      }
      else {
        for (let key in buttonShow) {
          buttonShow[key] = false
        }

      }

    }
    return buttonShow;
  },

  //将数组拆分成二维数组
  groupedItems: function (item, chunkSize) {
    const result = [];
    for (let i = 0; i < item.length; i += chunkSize) {
      const chunk = item.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  },

  //检测字段是否为空
  checkFields: function (obj, fields) {
    let result = true
    for (let field of fields) {
      console.log(field, obj[field])

      try {
        if (obj[field].length == 0) {
          result = false
          console.log(field)
          break
        }
      }
      catch {
        if (obj[field] === "" || obj[field] === null || obj[field] === undefined) {
          result = false
          break
        }
      }


    }
    return result
  },

  //去除字典中的值为null的键
  removeNullValues: function (obj) {
    return Object.keys(obj).reduce((result, key) => {
      if (obj[key] !== null) {
        result[key] = obj[key];
      }
      return result;
    }, {});
  },

  //去除字典中的值为null和空字符串的键
  removeNullStringValue: function (obj) {
    return Object.keys(obj).reduce((result, key) => {
      if (obj[key] !== null && obj[key] !== "") {
        result[key] = obj[key];
      }
      return result;
    }, {});
  }

}


