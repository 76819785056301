<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div class='container-page'>
    <div class='title-box'>
      <div class='title'>工单申请</div>
    </div>
    <div class='content-box'>
      <div class='order-box'>
        <div class='order-box-item'>
          <div class='title'>工单类型</div>
          <div class='type-box'>
            <div :class="{ 'type-item': !orderTypeRef, 'type-item-right': orderTypeRef }"
                 @click="orderTypeClickChange('报修')">
              报修
            </div>
            <div :class="{ 'type-item': orderTypeRef, 'type-item-right': !orderTypeRef }"
                 @click="orderTypeClickChange('新装')">
              新装
            </div>
          </div>
        </div>

        <div class='order-box-item'>
          <div class='title'><span style="color: red;margin-left: 0px;"
                  v-if='baseData.type=="报修"'>*</span>选择门店</div>
          <div class='common-box'>
            <a-select :bordered='false'
                      :showArrow='false'
                      :placeholder="`请选择门店`"
                      show-search
                      class='white-font'
                      :filter-option="filterOption"
                      :options='options.store'
                      style='width:344px'
                      v-model:value="baseData.storeId"
                      @change='storeChange'></a-select>
          </div>
        </div>
        <!-- <div class='order-box-item'>
          <div class='title'>品牌</div>
          <div class='common-box'></div>
        </div> -->
        <div class='order-box-item'>
          <div class='title'><span style="color: red;margin-left: 0px;"
                  v-if='baseData.type=="新装"'>*</span>门店名称</div>
          <div class='common-box'><a-input :bordered='false'
                     v-model:value="baseData.storeName"
                     class='white-font'
                     :placeholder="`请输入门店名称`"></a-input></div>
        </div>

        <div class='order-box-item'>
          <div class='title'><span style="color: red;margin-left: 0px;"
                  v-if='baseData.type=="新装"'>*</span>产品信息</div>
          <div class='common-box'><a-input :bordered='false'
                     v-model:value="baseData.productInfo"
                     class='white-font'
                     :placeholder="`请输入产品信息，未知可填无`"></a-input></div>
        </div>

        <div class='order-box-item'>
          <div class='title'><span style="color: red;margin-left: 0px;"
                  v-if='baseData.type=="新装"'>*</span>区域</div>
          <div class='common-box'> <a-cascader :showArrow="false"
                        :bordered="false"
                        style='width:344px'
                        v-model:value="baseData.regionIds"
                        :show-search="{ filter }"
                        class='white-font'
                        :options="options.region"
                        :placeholder="`请选择区域`" /></div>
        </div>

        <div class='order-box-item'>
          <div class='title'><span style="color: red;margin-left: 0px;"
                  v-if='baseData.type=="新装"'>*</span>门店地址</div>
          <div class='common-box'><a-input :bordered='false'
                     v-model:value="baseData.address"
                     class='white-font'
                     :placeholder="`请输入门店地址`"></a-input></div>
        </div>

        <div class='order-box-item'>
          <div class='title'><span style="color: red;margin-left: 0px;"
                  v-if='baseData.type=="新装"'>*</span>联系人</div>
          <div class='common-box'><a-input :bordered='false'
                     v-model:value="baseData.contactPhone"
                     class='white-font'
                     :placeholder="`请输入联系人`"></a-input></div>
        </div>

        <div class='order-box-item'>
          <div class='title'><span style="color: red;margin-left: 0px;"
                  v-if='baseData.type=="新装"'>*</span>联系电话</div>
          <div class='common-box'><a-input :bordered='false'
                     v-model:value="baseData.contactName"
                     class='white-font'
                     :placeholder="`请输入联系电话`"></a-input></div>
        </div>

        <div class='order-box-big-item'>
          <div class='common-big-box-title'><span style="color: red;margin-left: 0px;">*</span>问题描述</div>
          <div class='common-big-box'> <a-textarea :bordered='false'
                        v-model:value="baseData.desc"
                        class='white-font'
                        :rows="4"
                        :placeholder="`请输入问题描述`"></a-textarea></div>
        </div>

        <div class='order-box-big-item'>
          <div class='common-big-box-title'>附件</div>
          <div>
            <div class='order-box-item-attachment'>
              <div class='order-box-item-attachment-image-box'>
                <img class='order-box-item-attachment-image'
                     src='@/static/demo/order/上传图片1.png' />
              </div>
              <div class="upload-box">
                <div class="upload-box-up">
                  <div class="upload-box-item-up">JPG或PNG格式</div>
                  <div class="upload-box-item-down">可上传多个文件</div>
                </div>
                <div class="upload-box-down">
                  <uploadComponent :fileIds='fileList'
                                   :freshFlag='uploadFresh'
                                   :inputShow='false'
                                   :descStyle="{'color':'#eceef0'}"
                                   @update:fileIds='(event)=>fileChange(event)'>
                  </uploadComponent>
                </div>

              </div>

            </div>

            <div @click='submitOrder'
                 class='submit-operation-box'>
              确认工单信息并提交
            </div>
          </div>

        </div>

      </div>

      <div class='tips-box'>
        <div class='arrow-box'>
          <div class='arrow-item-one'>选择工单类型</div>
          <div class='arrow-item'>输入信息</div>
          <div class='arrow-item'>提交</div>
        </div>

        <div class='tips-message-box'>
          <img class='tips-message-box-image'
               src='@/static/demo/order/信息.png' />
          <div class='tips-content'>
            请尽量清晰详尽的描述您的问题，以便工作人员能够快速帮您
            解决问题!
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import uploadComponent from '@/components/uploadComponent.vue'
import serviceAxios from "@/utils/serviceAxios";
import removeNullValues from "@/utils/removeNullValues";
import { message } from "ant-design-vue";
import { reactive, ref, onMounted, onUpdated, watch, computed } from "vue";
import interfaces from "@/config";
import { useRouter, useRoute } from 'vue-router';
import { checkTree } from "@/utils/regionTree";
import { selector } from '@/utils/selector'
import dayjs from "dayjs";

import "dayjs/locale/zh-cn";


import zhCN from 'ant-design-vue/es/locale/zh_CN';
const locale = zhCN;
dayjs.locale("zh-cn");

let route = useRoute()
let router = useRouter()


//定义类型点击字段
let orderTypeRef = ref(false)

//定义类型点击事件
const orderTypeClickChange = (type) => {
  orderTypeRef.value = type == '新装' ? true : false

  baseData.type = type
  for (let key in baseData) {
    if (!['regionIds', 'type', , 'brandId', , 'storeId'].includes(key)) {
      baseData[key] = ''
    }

  }
  baseData.storeId = null
  baseData.regionIds = []
}
// table列名数据
const columns = ref([

  {
    title: "工单号",
    dataIndex: "workSn",
    key: "workSn",
    width: '180px',
  },
  {
    title: "工单状态",
    width: '100px',
    dataIndex: "status",
    key: "status"
  },
  {
    title: "工单类型",
    width: '100px',
    dataIndex: "type",
    key: "type"
  },

  {
    title: "门店名称",
    dataIndex: "storeName",
    key: "storeName",
    width: '150px',
    ellipsis: true
  },

  {
    title: "门店地址",
    dataIndex: "address",
    key: "address",
    // width: '200px',
    ellipsis: true
  },
  {
    title: "联系人",
    dataIndex: "contactName",
    key: "contactName",
    width: '100px',
  },
  {
    title: "联系电话",
    dataIndex: "contactPhone",
    key: "contactPhone",
    width: '120px',
  },
  {
    title: "问题描述",
    dataIndex: "desc",
    key: "desc",
    width: 'true',
    ellipsis: true
  },
  {
    title: "拒绝原因",
    dataIndex: "rejectReason",
    key: "rejectReason",
    width: '100px',
    ellipsis: true
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    key: "createTime",
    width: '160px',
  },
  {
    title: "操作",
    dataIndex: "operation",
    key: "operation",
    width: '150px',
  }
]);

//table数据源
const dataSource = reactive(
  {
    data: [],
  }
)

//选择框数据源
const options = reactive({
  region: [],
  type: [{
    label: "新装",
    value: "新装"
  },
  {
    label: "报修",
    value: "报修"
  },
  ],
  store: []
});

// 数据模型
const baseData = reactive({
  id: '',
  workSn: '',
  status: "",
  type: "报修",
  brand: '',
  brandId: '',
  storeName: '',
  storeId: null,
  contactName: '',
  contactPhone: '',
  address: '',
  region: '',
  regionIds: [],
  rejectReason: '',
  productInfo: "",
  desc: '',
  createTime: '',
})

const showModal = reactive({
  order: false,
})

const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryFields.page_num = current;
  queryFields.page_size = pageSize;
  queryPage(); //刷新数据
};

let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = selectedRows; //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = selectedRows;
    console.log(selected, selectedRows, changeRows);
  },
});




//title按钮事件 搜索、重置、新装工单
let queryFields = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
})
const searchData = reactive({
  type: '',
  keyword: '',
  status: '',
})
const searchFields = [{
  title: '类型',
  key: 'type'
},
{
  title: '关键字',
  key: 'keyword'
},
{
  title: '状态',
  key: 'status'
}
]
const sift = () => {
  queryFields = {
    page_num: pagination.current,
    page_size: pagination.pageSize,
    type: searchData.type,
    keyword: searchData.keyword,
    status: searchData.status,
  }
  queryPage()
}
const resetSift = () => {
  queryFields = {
    page_num: 1,
    page_size: 10,
  }
  queryPage()
}


//工单的增加、编辑、查看事件
let orderOperation = '' //判断是增加工单还是编辑工单
const orderFields = [
  {
    title: "工单类型",
    key: "type"
  },
  {
    title: "选择门店",
    key: "storeId",
  },
  {
    title: "门店名称",
    key: "storeName",
  },
  {
    title: "产品信息",
    key: "productInfo",
  },
  {
    title: "区域",
    key: "region",
  },
  {
    title: "门店地址",
    key: "address",

  },
  {
    title: "联系人",
    key: "contactName",
  },
  {
    title: "联系电话",
    key: "contactPhone",
  },
  {
    title: "问题描述",
    key: "desc",
  },
  {
    title: "附件",
    key: "attachments",
  }
]
//定义上传组件刷新属性
const uploadFresh = ref(true)
const resetOrder = () => {
  for (let key in baseData) {
    if (!['regionIds', 'type', 'brandId', 'storeId'].includes(key)) {
      baseData[key] = ''
    }

  }

  baseData.regionIds = []
  baseData.storeId = null
  baseData.type = '报修'
  fileList.value = []
  uploadFresh.value = !uploadFresh.value

}

const addOrder = () => {
  resetOrder()
  orderOperation = 'add'
  showModal.order = true
}

const editOrder = (record) => {
  orderOperation = 'edit'
  showModal.order = true
  for (let key in record) {
    baseData[key] = record[key]
  }
}

const viewOrder = () => {

}

const submitOrder = () => {
  let url = ''
  let data = ''
  let flag = true
  let checkRepairFields = ['storeId', 'desc']
  let checkNetworkFields = ['storeName', 'regionIds', 'address', 'contactName', 'contactPhone', 'desc']
  let attachment = fileList.value


  if (baseData.type == '报修') {
    for (let i = 0; i < checkRepairFields.length; i++) {
      if (baseData[checkRepairFields[i]] == '' || baseData[checkRepairFields[i]] == null || baseData[checkRepairFields[i]] == []) {
        flag = false
        break
      }
    }
  }
  else {
    for (let i = 0; i < checkNetworkFields.length; i++) {
      if (baseData[checkNetworkFields[i]] == '' || baseData[checkNetworkFields[i]] == null || baseData[checkNetworkFields[i]] == []) {
        flag = false
        break
      }
    }
  }
  if (flag == true) {

    url = baseData.type == '报修' ? interfaces.costomerRepairAdd : interfaces.costomerOrderAdd
    data = {
      customer_id: baseData.brandId,
      customer_name: baseData.brand,

      region_id: baseData.regionIds[baseData.regionIds.length - 1],
      contract_user_contact_name: baseData.contactName,
      contract_user_phone_number: baseData.contactPhone,
      data_attachment_ids: attachment,
      desc: baseData.desc == null ? '' : baseData.desc,
    }
    if (baseData.type == '报修') {
      data['contract_user_id'] = baseData.storeId

    } else {
      data['contract_user_name'] = baseData.storeName
      data['contract_user_address'] = baseData.address
    }




    serviceAxios({
      url: url,
      method: 'post',
      data: data
    }).then(result => {
      if (result.data.message == 'success') {
        showModal.order = false
        message.success('成功')
        resetOrder()
      }
      else {
        message.error(result.data.message)
      }
    }).catch()

  }
  else {
    message.error('请检查数据')
  }
}
//获取门店信息 用于工单modal的选择门店的option属性
const contractPage = () => {
  serviceAxios({
    url: interfaces.contractPage,
    method: "post",
    data: {
      page_num: 1,
      page_size: 99999
    }
  }).then(result => {
    let data = []
    result.data.data.list.forEach(item => {
      data.push({
        label: item.contract_user.name,
        value: item.contract_user.id,
        brand: item.customer.name,
        brandId: item.customer.id,
        storeId: item.contract_user.id,
        storeName: item.contract_user.name,
        contactName: item.contract_user.contact_name,
        contactPhone: item.contract_user.phone_number,
        address: item.contract_user.address,
        regionIds: item.region.full_path_ids,
      })
      baseData.brandId = item.customer.id //品牌ID

    })


    options.store = data

  }).catch((err) => {
    console.log(err);
  })
}
contractPage()




const getTreeData = async () => {
  const treeData = await checkTree()
  options.region = treeData[0]["children"]
  console.log("treeData", treeData)
}
getTreeData()


//select 选择框change事件 
//门店名称change
const storeChange = (value, option) => {
  console.log(value, option)
  for (let key in option) {
    if (key != 'storeId') {
      baseData[key] = option[key]
    }
  }
  console.log(baseData)
}

const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};


const filter = (inputValue, path) => {
  return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};
//文件处理
let fileList = ref([])
const fileChange = (events) => {
  fileList.value = []
  events.forEach(item => {
    fileList.value.push(item.isOld == true ? item.uid : item.response.data[0].id)
  })
}


//返回首页
const backHome = () => {
  router.push({ path: "/homePageData" })
}

//跳转添加工单
const toOrderList = () => {
  router.push({ path: "/customerWorkOrder" })
}
</script>
<style lang="less" scoped>
@import "@/assets/customer/submitOrder.css";
@import "@/assets/css/common.css";
</style>