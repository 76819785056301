<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <!-- 添加或者编辑数据弹窗 -->
  <a-modal width="500px"
           v-model:open="modalShow.addOrEdit"
           title="AIOps产品信息"
           ok-text="确认"
           cancel-text="取消"
           @ok="submit"
           :maskClosable="false">
    <table class='mailTable'>

      <tr>
        <td class='column'>真实姓名</td>
        <td>
          <a-input v-model:value="baseData.name"
                   :bordered="false"
                   placeholder="请输入真实姓名"></a-input>
        </td>
      </tr>
      <tr>
        <td class='column'>用户名 <span style="color: red;margin-left: 0px;">*</span></td>
        <td>
          <a-input v-model:value="baseData.phone"
                   :bordered="false"
                   placeholder="请输入用户名"></a-input>
        </td>
      </tr>
      <tr>
        <td class='column'>密码 <span style="color: red;margin-left: 0px;">*</span></td>
        <td>
          <a-input v-model:value="baseData.password"
                   :bordered="false"
                   placeholder="请输入密码"></a-input>
        </td>
      </tr>
      <tr>
        <td class='column'>门店 <span style="color: red;margin-left: 0px;">*</span></td>
        <td>
          <a-select v-model:value="baseData.storeIds"
                    mode="multiple"
                    style="width: 100%"
                    :bordered="false"
                    placeholder="请选择门店"
                    :options="options.store"
                    :filter-option="filterOption"
                    @change="handleChange"></a-select>
        </td>
      </tr>

    </table>
  </a-modal>

  <!-- 确认删除弹窗 -->
  <a-modal :maskClosable="false"
           v-model:open="modalShow.isDeleteModalVisible"
           title="确认删除"
           @ok="handleOkDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>

  <TableComponent :columns="fieldAll.columns"
                  :data-source="dataSource.data"
                  :row-selection="rowSelection"
                  :pagination="pagination"
                  @change="handleTableChange"
                  style=" overflow: hidden !important; white-space: nowrap;  text-overflow: ellipsis;">
    <template #bodyCell="{ column, record }">
      <template v-if="['names'].includes(column.dataIndex)">
        <div>
          <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a>
        </div>
      </template>
      <template v-else-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <a class='editable-row-a'
             @click="editData(record)">编辑</a>
          <a class='editable-row-a'
             @click="deleteData(record.id)">删除</a>
        </div>
      </template>

    </template>
    <template #title>
      <div class='searchDivContainer'>
        <div class='searchDivContainer'>

          <a-input v-model:value="searchData.keyword"
                   addonBefore='关键字'
                   :placeholder="`输入关键字`"></a-input>

          <a-button style="margin:2px"
                    type="primary"
                    @click="sift">搜索</a-button>
          <a-button style="margin:2px"
                    @click="resetSift">重置</a-button>
        </div>
        <div class='right-buttons'>

          <a-button class="addDataButton"
                    v-if="buttonShow.add"
                    type="primary"
                    @click="addData">添加</a-button>
          <a-button class="addDataButton"
                    v-if="buttonShow.delete"
                    type="primary"
                    @click="deleteData">删除数据</a-button>
        </div>
      </div>
    </template>

    <!-- <template #footer>Footer</template> -->
  </TableComponent>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import serviceAxios from "@/utils/serviceAxios";
import encrypt from "@/utils/encrypt"
import { commonFun } from "@/utils/commonFun";
import { selector } from "@/utils/selector";
import { checkTree } from "@/utils/regionTree";
import { decimalUtils } from "@/utils/decimalUtils";
import { message } from "ant-design-vue";
import { reactive, ref, watch } from "vue";
import interfaces from "@/config";
import { useRouter, useRoute } from 'vue-router'
import dayjs from "dayjs";

const url = {
  add: interfaces.addSubAccount,
  delete: interfaces.deleteSubAccount,
  edit: interfaces.updateSubAccount,
  page: interfaces.subAccountPage,
}


//按钮的显示
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
})



// const getButtonRules = () => {
//   let route = useRoute()
//   if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
//     let rules = JSON.parse(localStorage.getItem("rules"))
//     rules = rules[route.fullPath]
//     if (rules != undefined) {
//       for (let key in buttonShow) {
//         buttonShow[key] = rules.includes(url[key]) ? true : false
//       }
//     }
//     else {
//       for (let key in buttonShow) {
//         buttonShow[key] = false
//       }
//     }

//   }
// }

// getButtonRules()


const fieldAll = reactive({
  // table列名数据
  columns: [
    { title: "用户名", dataIndex: "phone", key: "phone" },
    { title: "真实姓名", dataIndex: "name", key: "name" },
    { title: "门店", dataIndex: "store", key: "store" },
    { title: "操作", dataIndex: "operation", key: "operation" }

  ],

  //弹框字段数据
  modalData: [
    { title: "用户名", dataIndex: "phone", key: "phone", require: true },
    { title: "密码", dataIndex: "password", key: "password", require: true },
    { title: "真实姓名", dataIndex: "name", key: "name" },
    { title: "门店", dataIndex: "store", key: "store", require: true },
  ]


})

// 弹框字段的二维数组
const groupFields = commonFun.groupedItems(fieldAll.modalData, 2)



//table数据源
const dataSource = reactive({
  //table的基础数据
  data: "",
})



//选择框数据
const options = reactive({
  store: []
});

// 数据模型
const baseData = reactive({
  name: "",
  id: "",
  phone: "",
  password: "",
  hashedPassword: "",
  store: "",
  storeIds: ""
})


//弹框显示
const modalShow = reactive({
  addOrEdit: false,
  isDeleteModalVisible: false,
  searchView: false,
  unit_price: false, // 收费金额是否可编辑
})

let urlFlag = "" //判断是增加数据还是编辑数据


const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryFields.page_num = current;
  queryFields.page_size = pageSize;
  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = reactive({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

let queryFields = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,

})

const queryPage = () => {
  serviceAxios({
    url: url.page,
    method: 'get',
    params: queryFields
  }).then(
    result => {
      const data = result.data.data.list
      dataSource.data = data.map(item => ({
        id: item.id,
        key: item.id,
        phone: item.username,
        name: item.real_name,
        username: item.username,
        hashedPassword: item.hashed_password,
        latestLogin: item.latest_login,
        roleId: item.role_id,
        store: item.stores.map(item => item.name).join(),
        storeIds: item.stores.map(item => item.id)
      }))
    }
  ).catch(error => {
    console.log(error)
    message.error('无权限')
  })
}
queryPage()
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    baseData[key] = null;
  }
  baseData.storeIds = []

};




//编辑数据
const editData = (record) => {
  urlFlag = url.edit
  modalShow.addOrEdit = true;
  //modalShow.unit_price = true;
  for (let key in baseData) {
    baseData[key] = record[key];
  };
  baseData.store = record.store == '' ? [] : record.store;
  baseData["hashedPassword"] = record.hashedPassword;
  fieldAll.modalData.filter(item => item.dataIndex == 'password')[0]['require'] = false;


};


//增加数据
const addData = () => {
  resetForm();
  modalShow.unit_price = false;
  baseData.is_package = 0;
  modalShow.addOrEdit = true;
  urlFlag = url.add
};



//判断是勾选删除还是点击删除
let deleteFlag = reactive({
  flag: "",
  data: ""
})

//删除数据
const deleteData = (data) => {
  console.log(typeof (data))
  if (typeof (data) == 'number') {
    modalShow.isDeleteModalVisible = true;
    deleteFlag.flag = "single"
    deleteFlag.data = data
  }
  else {
    if (selectedRowKey.value.length === 0) {
      message.error("请至少勾选一条数据");
    } else {
      modalShow.isDeleteModalVisible = true;
      deleteFlag.flag = "much"
    }
  }

};

//确认删除数据
const handleOkDelete = () => {
  // 执行删除操作
  // console.log("执行删除操作");
  let ids = [];
  if (deleteFlag.flag == 'single') {
    ids = [deleteFlag.data]
  }
  else if (deleteFlag.flag == 'much') {

    ids = rowData.map(item => item.id)
  }
  serviceAxios({
    url: url.delete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(
      result => {
        if (result.data.message == 'success') {
          deleteFlag = {
            flag: "",
            data: ""
          }
          queryPage()
          message.success("删除成功");

        }
        else {
          message.error(result.data.message);
        }
      }
    )
    .catch((err) => {
      console.log(err)
      message.error("请求异常，请联系管理员");
    });
  modalShow.isDeleteModalVisible = false;
};



//提交
const submit = () => {
  let flag = true
  //检测必填字段
  let mustInputFields = fieldAll.modalData.filter(item => item.require == true)
  mustInputFields.forEach(item => {
    if (baseData[item.dataIndex] == '') {
      flag = false
      message.error(`${item.title}字段不能为空`)
    }
  })


  if (flag == true) {
    const data = urlFlag == url.add ? {
      real_name: baseData.name,
      phone: baseData.phone,
      hashed_password: baseData.password == '' ? baseData.hashedPassword : encrypt.addUserCrypto(baseData.password),
      store_ids: baseData.storeIds
    } : {
      id: baseData.id,
      real_name: baseData.name,
      store_ids: baseData.storeIds
    }
    serviceAxios({
      url: urlFlag,
      method: "post",
      data: data
    }).then(result => {
      if (result.data.message == 'success') {
        message.success("成功")
        modalShow.addOrEdit = false
        queryPage()
      }
      else {
        message.error(result.data.message)
      }


    }).catch(err => {
      console.log(err)
      message.error("处理数据发生异常，请联系管理员")
    })
  }





};









//搜索数据
const searchData = reactive({
  region: [],
  keyword: "",
  customer_ids: "",
  is_package: "",
  searchViewFlag: false
})



//搜索
const sift = () => {
  queryFields["keyword"] = searchData.keyword

  queryPage()
}

//重置搜索
const resetSift = () => {
  queryFields = {
    page_num: 1,
    page_size: 10,
  }
  searchData.keyword = ""

  queryPage()
}

//select搜索框的搜索功能
const filterOption = (input, option) => {
  return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};


//自执行函数 获取门店选择器数据
(async () => {
  options.store = await selector.store()
  console.log(' options.store ', options.store)
})()

</script>
<style lang="less">
.searchDivContainer {
  display: flex;
  // align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
  padding: 0px 5px 5px 5px;
}

.right-buttons {
  margin-top: 0px;
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */

  //   position: fixed;
  // top: 10px; /* 调整此值以设定距离顶部的距离 */
  // right: 10px; /* 调整此值以设定距离右侧的距离 */
  // z-index: 1000; /* 确保按钮位于其他内容之上，根据需要调整 */
}

.addDataButton {
  margin-top: 0px;
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.editable-row-operations {
  // margin-bottom: 8px;
}
.editable-row-a {
  margin: 2px;
  color: #1890ff;
}

.searchTable {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
}
.searchTable tr td {
  width: 150px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.searchTable tr td.column {
  background-color: #eff3f6;
  color: #393c3e;
  width: 11%;
}
</style>