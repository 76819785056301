<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <div class='modal-box'>
    <a-modal :maskClosable="false"
             v-model:open="showModal.storeSetting"
             title="报警设置"
             width="550px"
             @ok="submitStoreSetting"
             cancelText='取消'
             class="modal-style"
             okText='提交'>

      <a-spin :spinning="spinning">
        <a-form :model="baseData"
                ref="formRef"
                :label-col="{ span:6}"
                :wrapper-col="{ span: 13 }"
                :rules="rules">

          <a-row :gutter="24">
            <template v-for='item in fieldAll.modalData_storeSetting'
                      :key='item.key'>
              <a-col :span="24">
                <a-form-item :label="item.title"
                             :name="item.dataIndex">
                  <a-select v-if='item.dataIndex=="store_id"'
                            v-model:value="baseData[item.dataIndex]"
                            show-search
                            :filter-option="filterOption"
                            :options="options['store']"
                            :placeholder="`请选择${item.title}`" />

                  <a-input v-else
                           v-model:value="baseData[item.key]"
                           :placeholder="`请输入${item.title}`"></a-input>
                </a-form-item>
              </a-col>

            </template>
          </a-row>
        </a-form>
      </a-spin>

    </a-modal>

  </div>
  <div class='container-page'
       ref='containerRef'>
    <div class="title-box">
      <div class='title'>
        IOT门店配置
      </div>
    </div>

    <div class='searchContainer'>
      <div class="keyword-box">
        <img class='keyword-image'
             src='@/static/demo/customer/搜索1.png' />
        <a-input v-model:value="siftRef.keyword"
                 class='keyword'
                 :bordered='false'
                 :showArrow='false'
                 placeholder="请输入关键字">
        </a-input>
      </div>

      <div class="
                  right-buttons">
        <div class='sift'
             @click="sift">
          <img class='sift-image'
               src='@/static/demo/customer/搜索1.png' />
          <div>
            搜索
          </div>
        </div>
        <div class='resetSift'
             @click="resetSift">
          <img class='reset-image'
               src='@/static/demo/customer/重置.png' />
          <div>
            重置
          </div>
        </div>
        <!-- <a-button type="primary"
                  @click="sift"
                  style="margin: 5px">搜索</a-button>
        <a-button type="primary"
                  @click="resetSift"
                  style="margin: 5px 0px 5px 0px">重置</a-button> -->
      </div>

    </div>

    <div class='table-box'>
      <a-table :scroll="ne"
               :columns="fieldAll.columns"
               :data-source="dataSource.data"
               :row-selection="rowSelection"
               :pagination="false"
               @change="handleTableChange"
               class="ant-table-striped"
               :row-class-name="(_record, index) => (index % 2 == 1 ? 'table-striped' : null)"
               :bordered=false
               style=" overflow: hidden; white-space: nowrap;  text-overflow: ellipsis;height:780px">
        <template #bodyCell="{ column, record }">
          <template v-if="['operationalStatus'].includes(column.dataIndex)">
            <div class="status-light">
              <a @click="operationalStatusClick(record.id)"
                 v-if='record.operationalStatus'
                 class="green-light"></a>
              <a @click="operationalStatusClick(record.id)"
                 v-if='!record.operationalStatus'
                 class="red-light"></a>

            </div>
          </template>

          <template v-else-if="column.dataIndex === 'operation'">
            <div>
              <a class='operation-a'
                 style='margin-left:0px'
                 @click="edit(record)">报警设置</a>
              <a class='operation-a'
                 style='margin-left:0px'
                 @click="toEnergy(record)">电量传感器</a>
              <a class='operation-a'
                 style='margin-left:0px'
                 @click="toTemperature(record)">温度传感器</a>

            </div>
          </template>

          <template v-else-if="column.dataIndex === 'router'">
            <a @click=toWeb(record.web)
               class='editable-row-a'>{{record.router}}</a>
          </template>
        </template>
        <template #title>

        </template>
      </a-table>

      <div class='footer-pagination'>
        <div class='botton-style'
             @click='previous'
             :style="pagination.current > 1 ? { color: 'white' } : { color: '#999' }">上一页</div>
        <div class='pagination-text'>当前第 {{pagination.current}} 页 / 共 {{pagination.allPage}} 页 </div>
        <div class='botton-style'
             @click='nextPage'
             :style="pagination.current < pagination.allPage ? { color: 'white' } : { color: '#999' }">下一页</div>
      </div>

    </div>
  </div>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import serviceAxios from "@/utils/serviceAxios";
import { message } from "ant-design-vue";
import { reactive, ref, h, computed } from "vue";
import { selector } from '@/utils/selector'
import { useRouter, useRoute } from 'vue-router'
import zhCN from 'ant-design-vue/es/locale/zh_CN';
const locale = zhCN;
import {
  LoadingOutlined
} from '@ant-design/icons-vue';
const router = useRouter()
const url = {
  add: '/v1/iot/store_config/add_or_update',
  edit: '/v1/iot/store_config/add_or_update',
  page: '/v1/iot/store_config/page',
  refresh: '/v1/iot/store_config/page',
  get_by_Id: '/v1/iot/store_config/get_by_id'
}
const redColor = { color: 'red' }
const greenColor = { color: 'green' }

//确认加载指示符
const spinning = ref(false);


//按钮的显示 
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
})

const siftRef = ref({
  keyword: null,
  customer_id: null
})

// const getButton = () => {
//   let route = useRoute()
//   buttonShow = commonFun.getButtonRules(route.fullPath, url)
// }
// getButton()


const fieldAll = reactive({
  // table列名数据
  columns: [


    {
      title: "门店",
      dataIndex: "store_name",
      key: "store_name",
      ellipsis: true,

    },
    {
      title: "温度传感器厂商",
      dataIndex: "temperature_manufacturer",
      key: "temperature_manufacturer",
      ellipsis: true,

    },
    {
      title: "电力传感器厂商",
      dataIndex: "electricity_manufacturer",
      key: "electricity_manufacturer",
      ellipsis: true,

    },
    {
      title: "离线预警",
      dataIndex: "alert_offline",
      key: "alert_offline",
      ellipsis: true,

    },
    {
      title: "阈值预警",
      dataIndex: "alert_threshold",
      key: "alert_threshold",
      ellipsis: true,

    },
    {
      title: "区域",
      dataIndex: "region",
      key: "region",
      ellipsis: true,

    },



    // {
    //   title: "描述",
    //   dataIndex: "desc",
    //   key: "desc"
    // },
    // {
    //   title: "创建时间",
    //   dataIndex: "create_time",
    //   key: "create_time"
    // },

    {
      title: "操作",
      dataIndex: "operation",
      key: "operation",
      width: 300,
    }
  ],

  //弹框字段数据

  modalData_storeSetting: [


    {
      title: "门店",
      dataIndex: "store_id",
      key: "store_id",
    },
    {
      title: "电话报警手机号",
      dataIndex: "alert_tel_phones",
      key: "alert_tel_phones",
    },
    {
      title: "微信报警手机号",
      dataIndex: "alert_wx_phones",
      key: "alert_wx_phones",
    },
    {
      title: "短信报警手机号",
      dataIndex: "alert_sms_phones",
      key: "alert_sms_phones",
    },


  ]
})

//table数据源
const dataSource = reactive({
  //table的基础数据
  data: [],
})

//增加或者编辑的模态框规则
const rules = {

  store_id: [
    {
      required: true,
      message: "请选择门店",
    },
  ],


}

//选择框数据
const options = reactive({

  store: [],
  yesOrNo: [{ label: '是', value: 1 }, { label: '否', value: 0 }],
});

// 数据模型
const baseData = reactive({
  id: null,
  customer_id: null,
  store_id: null,
  alert_tel_phones: null,
  alert_wx_phones: null,
  alert_sms_phones: null,
})

//弹框显示
const showModal = reactive({
  alertSetting: false,
})



let urlFlag = "" //判断是增加数据还是编辑数据


//分页模型
const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 12, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["16", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
  allPage: computed(() => {
    return Math.ceil(pagination.total / pagination.pageSize);
  })
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = reactive({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
})

const queryPage = () => {
  serviceAxios({
    url: url.page,
    method: 'get',
    params: queryField
  }).then(result => {
    pagination.total = result.data.data.total;
    dataSource.data = result.data.data.list.map(item => ({
      id: item.id,
      key: item.id,
      customer_name: item.customer_name,
      tenant_name: item.tenant_name,
      store_name: item.store_name,
      store_id: item.store_id,
      temperature_manufacturer: item.temperature_manufacturer,
      electricity_manufacturer: item.electricity_manufacturer,
      electricity_store_name: item.electricity_store_name,
      alert_threshold: item.alert_threshold == 1 ? "开" : "关",
      alert_offline: item.alert_offline == 1 ? "开" : "关",
      region: item.region.map(item => item.name).join(" "),
      desc: item.desc,
      create_time: item.create_time,
      item: item

    }));
  }).catch(err => { console.log(err), message.error("获取数据失败，请联系管理员") })
}
queryPage()
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
  }
};

const formRef = ref()


//编辑数据
const edit = async (record) => {
  let response = await serviceAxios({
    url: url.get_by_Id,
    method: 'get',
    params: { store_id: record.item.store_id } // 确保参数格式正确
  });
  if (response.data.message == 'success') { // 假设成功响应的状态码为200且返回的data中有一个success标志
    let data = response.data.data;

    if (data) {
      // 更新 baseData 对象中的属性
      for (let key in baseData) {
        if (data.hasOwnProperty(key)) { // 确保 data 对象中有这个属性
          baseData[key] = data[key];
        }
      }
      baseData.alert_wx_phones = data.alert_wx_phones.join(" ")
      baseData.alert_sms_phones = data.alert_sms_phones.join(" ")
      baseData.alert_tel_phones = data.alert_tel_phones.join(" ")

      urlFlag = url.edit;
      showModal.storeSetting = true;
    } else {
      console.error('No valid data returned from the server.');
    }
  } else {
    console.error('Server did not return a success response.');
  }

};
//










//提交
const submitStoreSetting = async () => {
  console.log(baseData);
  try {
    await Promise.all([formRef.value.validate()])
    let data = {
      store_id: baseData.store_id,
      customer_id: baseData.customer_id,
      alert_wx_phones: baseData.alert_wx_phones ? baseData.alert_wx_phones.split(' ') : [],
      alert_sms_phones: baseData.alert_sms_phones ? baseData.alert_sms_phones.split(' ') : [],
      alert_tel_phones: baseData.alert_tel_phones ? baseData.alert_tel_phones.split(' ') : [],
    }
    spinning.value = true
    serviceAxios({
      url: "/v1/iot/store_alert_setting/edit",
      method: "post",
      timeout: 30000,
      data: data
    }).then(result => {
      if (result.data.message == 'success') {
        message.success("成功")
        showModal.storeSetting = false;
        queryPage()
      }
      else {
        message.info(result.data.message)
      }
      spinning.value = false
    }).catch(err => {
      console.log(err)
      message.error("接口超时")
      spinning.value = false

    })


  }
  catch (error) {
    message.error("请填写完整信息");
    console.log('error', error)
  }


};




//搜索
const sift = () => {
  for (let key in siftRef.value) {
    queryField[key] = siftRef.value[key]

  }
  queryPage()
}

//重置搜索
const resetSift = () => {
  queryField = {
    page_num: 1,
    page_size: pagination.pageSize,
  }
  siftRef.value.keyword = null
  siftRef.value.customer_id = null
  queryPage()
}



const refresh = () => {
  queryPage()
}





const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const filter = (inputValue, path) => {
  return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};


(async () => {

  options.store = await selector.store()
})()

const toTemperature = (record) => {
  router.push({
    path: "/customerTemperatureSensorList",
    query: { storeId: record.store_id }
  })
}

const toEnergy = (record) => {
  router.push({
    path: "/customerEnergySensorList",
    query: { storeId: record.store_id }
  })
}




</script>
<style lang="less" scoped>
@import "@/assets/css/tablePage.css";
@import "@/assets/css/antTable.css";
@import "@/assets/css/pageTitle.css";
</style>