// const md5 = require('js-md5');
// const bcryptjs = require('bcryptjs');

import md5 from 'js-md5';
import bcryptjs from 'bcryptjs';
// $2a$10$jGLrGh6lUR3fNWG4KfCd2O0NfcPbtSP9rtpkkz5u7l3cAG0.jyMsy
// 使用md5函数进行加密  
const md5Crypto = (str) => {
  // let hash = md5.arrayBuffer('需要加密的字符串');    // 二进制形式的哈希值 
  let hashHex = md5.hex(str);   // 十六进制形式的哈希值
  return hashHex
}

const bcryptCrypto = (password) => {
  const hashPassword = bcryptjs.hashSync(password)
  return hashPassword
}

const addUserCrypto = (password) => {
  return md5Crypto(password)
}

const loginUserCrypto = (password) => {
  return bcryptCrypto(md5Crypto(password))
}
// console.log(loginUserCrypto("MJYong@240606"))

export default { loginUserCrypto, addUserCrypto }