//检测缓存数据，如果不存在就直接跳转到登录界面
//检测数据 token 地区数据 角色数据 导航栏数据 
import router from '../router'
let result = ""
const checkData = () => {
  // console.log("开始检测缓存")
  let token = localStorage.getItem('token') == null ? false : true
  // let region = localStorage.getItem('region') == null ? false : true
  let role = localStorage.getItem('role') == null ? false : true
  // let sides = localStorage.getItem('sides') == null ? false : true
  let id = localStorage.getItem('id') == null ? false : true
  // let headers = localStorage.getItem('headers') == null ? false : true
  let userName = localStorage.getItem('userName') == null ? false : true
  // console.log("检测缓存", token, region, role, sides, id, headers, userName)
  if (token && role && id && userName) {

    result = true
  }
  else {
    localStorage.clear()
    result = false
  }
  // console.log("检测结果result", result)
  return result
}

export default { checkData }