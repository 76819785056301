import axios from 'axios'
import router from '../router'


const proxyRequest = axios.create({
  //根据运行环境来选择相应接口地址
//   baseURL: process.env.VUE_APP_URL,
  baseURL:'/api',
  timeout: 30000, //设置超时
  withCredntials: true, // 跨域请求是否需要携带 cookie
})




export default proxyRequest