<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-v-model-argument -->
<template>

  <div class='container-page'
       ref='containerRef'>
    <div class='modal-box'>
      <a-modal :maskClosable="false"
               v-model:open="showModal.order"
               title="工单"
               width="550px"
               @ok="submitOrder"
               cancelText='取消'
               class="modal-style"
               okText='提交'>
        <div class='orderTable-box'>
          <template v-for='item in orderFields'
                    :key='item.key'>
            <div v-if='item.title=="工单类型"'
                 class='modal-table-item'>
              <div class='column'>{{item.title}}</div>
              <div>
                <a-select :bordered='false'
                          :showArrow='false'
                          :placeholder="`请选择${item.title}`"
                          :options='options.type'
                          style='width:344px'
                          v-model:value="baseData.type"
                          @change='typeChange'></a-select>
              </div>
            </div>
            <div v-if='item.title=="选择门店" && baseData.type=="报修"'
                 class='modal-table-item'>
              <div class='column'><span style="color: red;margin-left: 0px;">*</span>{{item.title}}</div>
              <div>
                <a-select :bordered='false'
                          :showArrow='false'
                          :placeholder="`请选择${item.title}`"
                          show-search
                          :filter-option="filterOption"
                          :options='options.store'
                          style='width:344px'
                          v-model:value="baseData.storeId"
                          @change='storeChange'></a-select>
              </div>
            </div>
            <div v-else-if='item.title=="区域"'
                 class='modal-table-item'>
              <div class='column'><span style="color: red;margin-left: 0px;">*</span>{{item.title}}</div>
              <div>
                <a-cascader :showArrow="false"
                            :bordered="false"
                            style='width:344px'
                            v-model:value="baseData.regionIds"
                            :show-search="{ filter }"
                            :options="options.region"
                            :placeholder="`请选择区域`" />
              </div>
            </div>
            <div v-else-if='["描述"].includes(item.title)'
                 class='modal-table-item'>
              <div class='column'><span style="color: red;margin-left: 0px;">*</span>{{item.title}}</div>
              <div>
                <a-input :bordered='false'
                         v-model:value="baseData[item.key]"
                         :placeholder="`请输入${item.title}`"></a-input>
              </div>
            </div>
            <div v-else-if='["附件"].includes(item.title)'
                 class='modal-table-item'>
              <div class='column'>{{item.title}}</div>
              <div>

                <uploadComponent :freshFlag='uploadFresh'
                                 :fileIds='fileList'
                                 :inputShow='false'
                                 @update:fileIds='(event)=>fileChange(event)'>

                </uploadComponent>

              </div>
            </div>
            <div v-else-if='!["区域","工单类型","选择门店","描述","附件"].includes(item.title)'
                 class='modal-table-item'>
              <div class='column'> <span v-if='baseData.type=="开通"'
                      style="color: red;margin-left: 0px;">*</span>{{item.title}}</div>
              <div>
                <a-input :bordered='false'
                         v-model:value="baseData[item.key]"
                         :placeholder="`请输入${item.title}`"></a-input>

              </div>

            </div>
          </template>
        </div>
      </a-modal>

      <a-modal :maskClosable="false"
               v-model:open="showModal.orderDetail"
               title="工单详情"
               class="modal-style"
               :ok-button-props="{ style: { display: 'none' } }"
               :cancel-button-props="{ style: { display: 'none' } }"
               width="550px">
        <div class='orderTable-box'>
          <template v-for='item in orderDetailFields'
                    :key='item.key'>
            <div class='modal-table-item'>
              <div class='column'>{{item.title}}</div>
              <div>{{baseData[item.key]}}</div>
            </div>
          </template>
          <div v-if='baseData.status =="已拒绝"'
               class='modal-table-item'>
            <div class='column'>拒绝原因</div>
            <div>{{baseData.rejectReason}}</div>
          </div>
          <template v-for='work in baseData.workContent'
                    :key='work.id'>
            <template v-for='item in workType[work.type]'
                      :key='item.key'>
              <div v-if='item.title=="附件"'
                   class='modal-table-item'>
                <div class="column">{{item.title}}</div>
                <div>
                  <uploadComponent :freshFlag='uploadFresh'
                                   :fileIds='fileList'
                                   :inputShow='false'
                                   :isButtonShow='false'
                                   @update:fileIds='(event)=>fileChange(event)'>

                  </uploadComponent>
                </div>
              </div>
              <div class='modal-table-item'
                   v-else-if='item.title!="附件" && baseData.status !="已拒绝"'>
                <div class="column">{{item.title}}</div>
                <div>{{workRef[work.type][item.key]}}</div>
              </div>
            </template>
          </template>
        </div>
      </a-modal>
    </div>

    <div class="title-box">
      <div class='title'>
        工单列表
      </div>
    </div>
    <div class='searchContainer'>
      <div class="keyword-box">
        <img class='keyword-image'
             src='@/static/demo/customer/搜索1.png' />
        <a-input v-model:value="searchData.keyword"
                 class='keyword'
                 :bordered='false'
                 :showArrow='false'
                 placeholder="请输入相关关键字">
        </a-input>
      </div>
      <div class='sift-region-box'>
        <a-select :placeholder="`类型：请选择类型`"
                  style="width: 150px"
                  :bordered='false'
                  :showArrow='false'
                  class="region-box"
                  v-model:value="searchData.type"
                  :options='options.workType'
                  @change='siftTypeChange'>
        </a-select>

      </div>
      <div class='sift-region-box'>
        <a-select :placeholder="`状态：请选择状态`"
                  style="width: 150px"
                  class="region-box"
                  :bordered='false'
                  :showArrow='false'
                  v-model:value="searchData.status"
                  :options='options.orderStatus'>
        </a-select>

      </div>

      <div class="right-buttons">
        <div class='sift'
             @click="sift">
          <img class='sift-image'
               src='@/static/demo/customer/搜索1.png' />
          <div>
            搜索
          </div>
        </div>
        <div class='resetSift'
             @click="resetSift">
          <img class='reset-image'
               src='@/static/demo/customer/重置.png' />
          <div>
            重置
          </div>
        </div>
        <!-- <a-button type="primary"
                  @click="sift"
                  style="margin: 5px">搜索</a-button>
        <a-button type="primary"
                  @click="resetSift"
                  style="margin: 5px 0px 5px 0px">重置</a-button> -->
      </div>

    </div>
    <div class='table-box'>
      <a-table class="ant-table-striped"
               :row-class-name="(_record, index) => (index % 2 == 1 ? 'table-striped' : null)"
               :columns="columns"
               :data-source="dataSource.data"
               :row-selection="rowSelection"
               :pagination="false"
               bordered
               style="white-space: nowrap;">
        <template #bodyCell="{ column,record }">
          <template v-if="column.dataIndex === 'operation'">
            <div>
              <a v-if='["已申请","已拒绝"].includes(record.status)'
                 style="margin:2px;color: #1884ea;"
                 @click="editOrder(record)">编辑</a>
              <a v-if='!["已申请"].includes(record.status)'
                 style="margin:2px;color: #1884ea;"
                 @click="viewOrder(record)">查看工单</a>
            </div>
          </template>
        </template>

      </a-table>
      <div class='footer-pagination'>
        <div class='botton-style'
             @click='previous'
             :style="pagination.current > 1 ? { color: 'white' } : { color: '#999' }">上一页</div>
        <div class='pagination-text'>当前第 {{pagination.current}} 页 / 共 {{pagination.allPage}} 页 </div>
        <div class='botton-style'
             @click='nextPage'
             :style="pagination.current < pagination.allPage ? { color: 'white' } : { color: '#999' }">下一页</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import uploadComponent from '@/components/uploadComponent.vue'

import serviceAxios from "@/utils/serviceAxios";
import removeNullValues from "@/utils/removeNullValues";
import { message } from "ant-design-vue";
import { reactive, ref, onMounted, onUpdated, watch, computed } from "vue";
import interfaces from "@/config";
import { useRouter, useRoute } from 'vue-router';
import { checkTree } from "@/utils/regionTree";
import { selector } from '@/utils/selector'
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";


import zhCN from 'ant-design-vue/es/locale/zh_CN';
const locale = zhCN;
dayjs.locale("zh-cn");
const uploadFresh = ref(true)

let route = useRoute()
let router = useRouter()

// table列名数据
const columns = ref([

  {
    title: "工单号",
    dataIndex: "workSn",
    key: "workSn",
    ellipsis: true

  },
  {
    title: "工单状态",
    dataIndex: "status",
    key: "status",
    ellipsis: true
  },
  {
    title: "工单类型",
    dataIndex: "type",
    key: "type",
    ellipsis: true
  },

  {
    title: "门店名称",
    dataIndex: "storeName",
    key: "storeName",
    ellipsis: true
  },

  {
    title: "门店地址",
    dataIndex: "address",
    key: "address",
    ellipsis: true
  },
  {
    title: "联系人",
    dataIndex: "contactName",
    key: "contactName",
    ellipsis: true

  },
  {
    title: "联系电话",
    dataIndex: "contactPhone",
    key: "contactPhone",
    ellipsis: true
  },
  {
    title: "描述",
    dataIndex: "desc",
    key: "desc",
    ellipsis: true
  },
  // {
  //   title: "拒绝原因",
  //   dataIndex: "rejectReason",
  //   key: "rejectReason",
  //   width: '100px',
  //   ellipsis: true
  // },
  {
    title: "创建时间",
    dataIndex: "createTime",
    key: "createTime",
    ellipsis: true
  },
  {
    title: "操作",
    dataIndex: "operation",
    key: "operation",
    width: '150px',
  }
]);

//table数据源
const dataSource = reactive(
  {
    data: [],
  }
)

//选择框数据源
const options = reactive({
  region: [],
  type: [{
    label: "开通",
    value: "开通"
  },
  {
    label: "报修",
    value: "报修"
  },
  ],
  store: [],
  orderStatus: [],
  workType: []
});

// 数据模型
const baseData = reactive({
  id: '',
  workSn: '',
  status: "",
  type: "报修",
  brand: '',
  brandId: '',
  storeName: '',
  storeId: null,
  contactName: '',
  contactPhone: '',
  address: '',
  region: '',
  regionIds: [],
  attachmentIds: [],
  rejectReason: '',
  desc: '',
  workContent: [],
  createTime: '',
})

const showModal = reactive({
  order: false,
  orderDetail: false,

})

const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 12, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
  allPage: computed(() => {
    return Math.ceil(pagination.total / pagination.pageSize);
  })
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryFields.page_num = current;
  queryFields.page_size = pageSize;
  queryPage(); //刷新数据
};

let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = selectedRows; //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = selectedRows;
    console.log(selected, selectedRows, changeRows);
  },
});




//title按钮事件 搜索、重置、开通工单
let queryFields = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
})
const searchData = reactive({
  type: null,
  keyword: '',
  status: null,
})
const searchFields = [{
  title: '关键字',
  key: 'keyword'
}, {
  title: '类型',
  key: 'type'
},

{
  title: '状态',
  key: 'status'
}
]
const sift = () => {
  queryFields = {
    page_num: pagination.current,
    page_size: pagination.pageSize,
    type: searchData.type,
    keyword: searchData.keyword,
    status: searchData.status,
  }
  queryPage()

  message.info("搜索成功")
}
const resetSift = () => {
  for (let key in searchData) {
    searchData[key] = null
  }
  queryFields = {
    page_num: 1,
    page_size: 12,
  }
  queryPage()
  message.info("重置成功")
}



//工作内容分类选择器
const workConentSelector = () => {
  serviceAxios({
    url: '/v1/work_order/work_order_type/selector',
    method: 'get',
  }).then(
    result => {
      let data = result.data.data
      options.workType = data.map(item => ({
        label: item.name,
        value: item.value
      }))
    }
  ).catch(
    error => {
      console.log(error)
    }
  )
}
workConentSelector()
//工单的增加、编辑、查看事件
let orderOperation = '' //判断是增加工单还是编辑工单
const orderFields = [
  {
    title: "工单类型",
    key: "type"
  },
  {
    title: "选择门店",
    key: "storeId",
  },
  {
    title: "门店名称",
    key: "storeName",
  },
  {
    title: "区域",
    key: "region",
  },
  {
    title: "门店地址",
    key: "address",

  },
  {
    title: "联系人",
    key: "contactName",
  },
  {
    title: "联系电话",
    key: "contactPhone",
  },
  {
    title: "描述",
    key: "desc",
  },
  {
    title: '附件',
    key: 'attachments'
  }
]

const resetOrder = () => {

  for (let key in baseData) {
    if (!['regionIds', 'type', 'brandId', 'storeId'].includes(key)) {
      baseData[key] = ''
    }

  }

  baseData.regionIds = []
  baseData.storeId = null
  baseData.type = '报修'
  fileList.value = []

  //刷新upload组件
  uploadFresh.value = !uploadFresh.value

}

const addOrder = () => {
  resetOrder()
  orderOperation = 'add'
  showModal.order = true
}

const editOrder = (record) => {
  uploadFresh.value = !uploadFresh.value
  orderOperation = 'edit'
  showModal.order = true
  for (let key in record) {
    baseData[key] = record[key]
  }
  if (record.workContent[0].data_attachments.length != 0) {
    // record.workContent[0].data_attachments.forEach(
    //   item => {
    //     fileList.value.push(item.id)
    //   }
    // )
    fileList.value = record.workContent[0].data_attachments.map(item => ({
      file_id: item.id,
      owner_class: item.owner_class,
      owner_id: item.owner_id
    }))
  }

}

const viewOrder = (record) => {

  fileList.value = []
  orderDetailFields.forEach(item => {
    baseData[item.key] = record[item.key]
  })
  baseData.rejectReason = record.rejectReason
  baseData.workContent = record.workContent
  if (record.workContent[0].data_attachments.length != 0) {
    record.workContent[0].data_attachments.forEach(
      item => {
        fileList.value.push(
          item.id
        )
      }
    )
  }

  if (baseData.workContent[0].type == '宽带接入') {
    workRef.value['宽带接入'].maintenancePhone = baseData.workContent[0].maintenance_phone
    workRef.value['宽带接入'].maintenanceName = baseData.workContent[0].maintenance_name
    workRef.value['宽带接入'].installerPhone = baseData.workContent[0].installer_name
    workRef.value['宽带接入'].installerName = baseData.workContent[0].installer_phone
  }
  // else if (baseData.workContent[0].type == '故障维修'){

  // }



  showModal.orderDetail = true
}

const submitOrder = () => {
  let url = ''
  let data = ''
  let flag = true
  let checkRepairFields = ['storeId', 'regionIds', 'desc']
  let checkNetworkFields = ['storeName', 'regionIds', 'address', 'contactName', 'contactPhone', 'desc']

  let attachment = fileList.value.map(item => item.file_id != undefined ? item.file_id : item)
  // fileList.value.forEach(item => {
  //   console.log("123", item.old)
  //   if (item.old != undefined && item.old == true) {
  //     attachment.push(item.uid)
  //   }
  //   else {
  //     if (item.response.message == 'success') {
  //       attachment.push(item.response.data[0].id)
  //     }
  //   }

  // })

  if (baseData.type == '报修') {
    for (let i = 0; i < checkRepairFields.length; i++) {
      if (baseData[checkRepairFields[i]] == '' || baseData[checkRepairFields[i]] == null || baseData[checkRepairFields[i]] == []) {
        flag = false
        break
      }
    }
  }
  else {
    for (let i = 0; i < checkNetworkFields.length; i++) {
      if (baseData[checkNetworkFields[i]] == '' || baseData[checkNetworkFields[i]] == null || baseData[checkNetworkFields[i]] == []) {
        flag = false
        break
      }
    }
  }
  if (flag == true) {
    if (orderOperation == 'add') {
      url = baseData.type == '报修' ? interfaces.costomerRepairAdd : interfaces.costomerOrderAdd
      data = {
        customer_id: baseData.brandId,
        customer_name: baseData.storeName,
        region_id: baseData.regionIds[baseData.regionIds.length - 1],
        contract_user_contact_name: baseData.contactName,
        contract_user_phone_number: baseData.contactPhone,
        data_attachment_ids: attachment,
        desc: baseData.desc == null ? '' : baseData.desc,
      }
      if (baseData.type == '报修') {
        data['contract_user_id'] = baseData.storeId

      } else {
        data['contract_user_name'] = baseData.storeName
        data['contract_user_address'] = baseData.address
      }

    }
    else if (orderOperation == 'edit') {
      url = baseData.type == '报修' ? interfaces.costomerRepairEdit : interfaces.costomerOrderEdit
      data = {
        id: baseData.id,
        desc: baseData.desc,
        contract_user_id: baseData.storeId,
        customer_id: baseData.brandId,
        contract_user_contact_name: baseData.contactName,
        contract_user_phone_number: baseData.contactPhone,
        customer_name: baseData.brand,
        region_id: baseData.regionIds[baseData.regionIds.length - 1],
        data_attachment_ids: attachment,
      }
      if (baseData.type != '报修') {
        data['contract_user_name'] = baseData.storeName
        data['contract_user_address'] = baseData.address
      }
    }



    serviceAxios({
      url: url,
      method: 'post',
      data: data
    }).then(result => {
      if (result.data.message == 'success') {
        showModal.order = false
        message.success('成功')
        queryPage()
      }
      else {
        message.error(result.data.message)
      }
    }).catch()

  }
  else {
    message.error('请检查数据')
  }
}
//获取门店信息 用于工单modal的选择门店的option属性
const contractPage = () => {
  serviceAxios({
    url: interfaces.contractPage,
    method: "post",
    data: {
      page_num: 1,
      page_size: 99999
    }
  }).then(result => {
    let data = []
    result.data.data.list.forEach(item => {
      data.push({
        label: item.contract_user.name,
        value: item.contract_user.id,
        brand: item.customer.name,
        brandId: item.customer.id,
        storeId: item.contract_user.id,
        storeName: item.contract_user.name,
        contactName: item.contract_user.contact_name,
        contactPhone: item.contract_user.phone_number,
        address: item.contract_user.address,
        regionIds: item.region.full_path_ids,
      })
      baseData.brandId = item.customer.id //品牌ID

    })


    options.store = data

  }).catch((err) => {
    console.log(err);
  })
}
contractPage()



//工单基础字段
const orderDetailFields = [
  {
    title: '工单类型',
    key: 'type'
  },
  {
    title: '工单状态',
    key: 'status'
  },

  {
    title: '门店名称',
    key: 'storeName'
  },
  {
    title: '地址',
    key: 'address'
  },
  {
    title: '联系人',
    key: 'contactName'
  },
  {
    title: '联系电话',
    key: 'contactPhone'
  },
  {
    title: '描述',
    key: 'desc'
  }
]
//工作内容字段 
const workType = {
  '故障维修': [{
    title: '附件',
    key: 'attachments'
  }],
  '宽带接入': [
    {
      title: '附件',
      key: 'attachments'
    },
    {
      title: '安装人',
      key: 'installerName'
    },
    {
      title: '安装人手机号',
      key: 'installerPhone'
    },
    {
      title: '维护人',
      key: 'maintenanceName'
    },
    {
      title: '维护人手机号',
      key: 'maintenancePhone'
    },
  ],
  '网关设备安装': [
    {
      title: '附件',
      key: 'attachments'
    }
  ],
  '测试报告': [
    {
      title: '附件',
      key: 'attachments'
    }
  ],
  '资源勘查': [
    {
      title: '附件',
      key: 'attachments'
    }
  ]
}
//工作内容Ref 
const workRef = ref({
  '故障维修': {
    attachments: []
  },
  '宽带接入': {
    attachments: [],
    installerName: "",
    installerPhone: "",
    maintenanceName: "",
    maintenancePhone: ""
  }
})



//向上翻页
const previous = () => {
  if (pagination.current > 1) {
    pagination.current -= 1
    queryFields.page_num = pagination.current
    queryPage()
  } else {

  }

}
//向下翻页
const nextPage = () => {
  if (pagination.current < pagination.allPage) {
    pagination.current += 1
    queryFields.page_num = pagination.current
    queryPage()
  }
  else {

  }

}



//请求
const queryPage = () => {
  serviceAxios({
    url: interfaces.constmerOrderPage,
    method: 'get',
    params: queryFields
  }).then(result => {
    if (result.data.message == 'success') {
      pagination.total = result.data.data.total;
      // 更新表格数据
      let data = []
      result.data.data.list.forEach(item => {
        data.push({
          id: item.id,
          key: item.id,
          workSn: item.work_sn,
          type: item.type,
          status: item.status,
          brand: item.customer_name,
          address: item.contract_user_address,
          brandId: item.customer_id,
          storeName: item.contract_user_name,
          storeId: item.contract_user_id,
          contactName: item.contract_user_contact_name,
          contactPhone: item.contract_user_phone_number,
          region: item.region,
          regionIds: item.region_path,
          rejectReason: item.reject_reason,
          desc: item.desc,
          workContent: item.work_contents,
          createTime: item.create_time,
        })
      });

      dataSource.data = data;
    }
    else {
      message.error(result.data.message)
    }

  }).catch(err => { console.log(err) })
}
queryPage()

const getTreeData = async () => {
  const treeData = await checkTree()
  options.region = treeData[0]["children"]

}
getTreeData()


//select 选择框change事件 
//门店名称change
const storeChange = (value, option) => {
  console.log(value, option)
  for (let key in option) {
    if (key != 'storeId') {
      baseData[key] = option[key]
    }
  }
  console.log(baseData)
}

//工单类型change
const typeChange = () => {
  for (let key in baseData) {
    if (!['regionIds', 'type', , 'brandId', , 'storeId'].includes(key)) {
      baseData[key] = ''
    }

  }
  baseData.storeId = null
  baseData.regionIds = []
}
const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const filter = (inputValue, path) => {
  return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};
//文件处理
let fileList = ref([])




const fileChange = (events) => {
  fileList.value = []
  events.forEach(item => {
    fileList.value.push(item.isOld == true ? item.uid : item.response.data[0].id)
  })
}
//搜索字段选择器
//工单类型选择器
const orderTypeSelector = async (type) => {
  options.orderStatus = await selector.workOrderStatus(type)

}
const siftTypeChange = (value) => {
  orderTypeSelector(value)
}
</script>
<style lang="less" scoped>
@import "@/assets/css/tablePage.css";
@import "@/assets/css/antTable.css";
@import "@/assets/css/pageTitle.css";
</style>