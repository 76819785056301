//租户界面特定的路由
export const specialRules = [
  {
    "id": 90,
    "key": "/homePageData",
    "label": "首页",
    "order": 0,
    "title": "首页",
    "is_bottom": 1,
    "parent_id": 25,

  },
  {
    "id": 91,
    "key": "/门店列表",
    "label": "门店信息",
    "order": 1,
    "title": "门店信息",
    "is_bottom": 0,
    "parent_id": 25,
    "children": [
      {

        "id": 92,
        "key": "/customerView",
        "label": "门店列表",
        "order": 0,
        "title": "门店列表",
        "is_bottom": 1,
        "parent_id": 91,

      },
      // {
      //   "id": 93,
      //   "key": "/storeMessage",
      //   "label": "运行状态",
      //   "order": 1,
      //   "title": "运行状态",
      //   "is_bottom": 1,
      //   "parent_id": 91,
      // },
      {
        "id": 94,
        "key": "/glopt",
        "label": "拓扑数据",
        "order": 2,
        "title": "拓扑数据",
        "is_bottom": 1,
        "parent_id": 91,

      }
    ]
  },
  {
    "id": 95,
    "key": "/gongdandemo",
    "label": "工单管理",
    "order": 2,
    "title": "工单管理",
    "is_bottom": 0,
    "parent_id": 25,
    "children": [
      {
        "id": 96,
        "key": "/ticketView",
        "label": "工单列表",
        "order": 2,
        "title": "工单列表",
        "is_bottom": 1,
        "parent_id": 95,

      },
      {
        "id": 98,
        "key": "/addTicket",
        "label": "添加工单",
        "order": 3,
        "title": "添加工单",
        "is_bottom": 1,
        "parent_id": 95,
      }
    ]
  },
  {
    "id": 101,
    "key": "/IoT",
    "label": "IoT",
    "order": 3,
    "title": "IoT",
    "is_bottom": 0,
    "children": [
      {
        "id": 105,
        "key": "/customeriotStoreConfig",
        "label": "门店配置",
        "order": 2,
        "title": "门店配置",
        "is_bottom": 1,

      },
      {
        "id": 108,
        "key": "/customerEnergySensorList",
        "label": "电量传感器",
        "order": 2,
        "title": "电量传感器",
        "is_bottom": 1,

      },
      {
        "id": 104,
        "key": "/shian",
        "label": "食安管理",
        "order": 2,
        "title": "食安管理",
        "is_bottom": 1,
        'children': [
          {
            "id": 102,
            "key": "/customerTemperatureSensorList",
            "label": "传感器列表",
            "order": 2,
            "title": "传感器列表",
            "is_bottom": 1,

          },
          {
            "id": 103,
            "key": "/sensorLogList",
            "label": "传感器日志列表",
            "order": 2,
            "title": "传感器日志列表",
            "is_bottom": 1,

          },
        ]
      },

      {
        "id": 105,
        "key": "/noopen105",
        "label": "能耗管理",
        "order": 2,
        "title": "能耗管理",
        "is_bottom": 1,

      },


    ]
  },

  {
    "id": 110,
    "key": "/noopen110",
    "label": "物联网卡管理",
    "order": 0,
    "title": "物联网卡管理",
    "is_bottom": 1,
    "parent_id": 25,
    // "children": [
    //   {
    //     "id": 111,
    //     "key": "/customerView",
    //     "label": "门店列表",
    //     "order": 0,
    //     "title": "门店列表",
    //     "is_bottom": 1,
    //     "parent_id": 91,
    //   },
    // ]
  },

  {
    "id": 120,
    "key": "/zichanguanli",
    "label": "资产管理",
    "order": 0,
    "title": "资产管理",
    "is_bottom": 1,
    "parent_id": 25,
    "children": [
      {
        "id": 121,
        "key": "/noopen1",
        "label": "资产登记",
        "order": 0,
        "title": "资产登记",
        "is_bottom": 1,
        "parent_id": 91,
      },

      {
        "id": 122,
        "key": "/noopen2",
        "label": "资产分类",
        "order": 0,
        "title": "资产分类",
        "is_bottom": 1,
        "parent_id": 91,
      },

      {
        "id": 123,
        "key": "/noopen3",
        "label": "资产清单",
        "order": 0,
        "title": "资产清单",
        "is_bottom": 1,
        "parent_id": 91,
      },

      {
        "id": 124,
        "key": "/noopen4",
        "label": "资产报表",
        "order": 0,
        "title": "资产报表",
        "is_bottom": 1,
        "parent_id": 91,
      },
    ]
  },
  {
    "id": 120,
    "key": "/xinfa",
    "label": "信发系统",
    "order": 0,
    "title": "信发系统",
    "is_bottom": 1,
    "parent_id": 25,
  }

]



