//小数处理
const Decimal = require('decimal.js');
export const decimalUtils = {

  //将数据补充到小数点两位小数
  ensureAtLeastTwoDecimals: (num) => {
    // console.log(num, typeof (num))
    let number = parseFloat(num)
    return number.toFixed(2);
    // return parseFloat(num.toFixed(2));  //如果你需要保持数字类型，可以使用 parseFloat 转换回来
  },


  //加法运算
  addWithPrecision: function (a, b) {
    const number1 = new Decimal(a);
    const number2 = new Decimal(b);
    const sum = number1.add(number2);

    return sum.toString()
  },
  //乘法运算
  multiplyWithPrecision: function (a, b) {
    const number1 = Decimal(a)
    const number2 = Decimal(b)
    const result = number1.mul(number2);
    return result.toString()
  },


};


// import { decimalUtils } from './yourModulePath';
// import { ensureAtLeastTwoDecimals, formatToTwoDecimals } from './yourModulePath';