<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-model-argument -->

<template>

  <!-- style=" white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" -->
  <div class='container-page'
       ref='containerRef'>
    <div class="title-box">
      <div class='title'>
        电量传感器列表
      </div>
    </div>

    <div class='searchContainer'>
      <div class="keyword-box">
        <img class='keyword-image'
             src='@/static/demo/customer/搜索1.png' />
        <a-input v-model:value="searchData.keyword"
                 class='keyword'
                 :bordered='false'
                 :showArrow='false'
                 placeholder="请输入关键字（无法搜索门店）">
        </a-input>
      </div>
      <div class='sift-region-box'>
        <!-- <a-select :showArrow="false"
                       class="region-box"
                       v-model:value="searchData['region']"
                       show-search
                       :bordered='false'
                       style="width: 150px"
                       :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                       placeholder="区域：请选择区域"
                       allow-clear
                       :tree-data="options['region']"
                       tree-node-filter-prop="label">
        </a-select> -->

        <a-select :showArrow="false"
                  :bordered='false'
                  v-model:value="searchData.store_id"
                  show-search
                  class="region-box"
                  :placeholder="`请选择门店`"
                  :options="options.store_id"
                  :filter-option="filterOption">
        </a-select>
      </div>

      <div class="
                  right-buttons">
        <div class='sift'
             @click="sift">
          <img class='sift-image'
               src='@/static/demo/customer/搜索1.png' />
          <div>
            搜索
          </div>
        </div>
        <div class='resetSift'
             @click="resetSift">
          <img class='reset-image'
               src='@/static/demo/customer/重置.png' />
          <div>
            重置
          </div>
        </div>
        <!-- <a-button type="primary"
                  @click="sift"
                  style="margin: 5px">搜索</a-button>
        <a-button type="primary"
                  @click="resetSift"
                  style="margin: 5px 0px 5px 0px">重置</a-button> -->
      </div>

    </div>

    <div class='table-box'>
      <a-table :scroll="ne"
               :columns="columns"
               :data-source="dataSource.data"
               :row-selection="rowSelection"
               :pagination="false"
               @change="handleTableChange"
               class="ant-table-striped"
               :row-class-name="(_record, index) => (index % 2 == 1 ? 'table-striped' : null)"
               :bordered=false
               style=" overflow: hidden; white-space: nowrap;  text-overflow: ellipsis;height:780px">
        <template #bodyCell="{ column, record }">
          <template v-if="['operationalStatus'].includes(column.dataIndex)">
            <div class="status-light">
              <a @click="operationalStatusClick(record.id)"
                 v-if='record.operationalStatus'
                 class="green-light"></a>
              <a @click="operationalStatusClick(record.id)"
                 v-if='!record.operationalStatus'
                 class="red-light"></a>

            </div>
          </template>

          <template v-else-if="column.dataIndex === 'operation'">
            <div>
              <a class='editable-row-a'
                 @click="toSensorDetails(record)">详情</a>
              <a class='editable-row-a'
                 v-if='record.offline_alert_count !=0'
                 @click="toLog(record)">查看日志</a>
              <!-- <a v-if='record.gateway && record.gateway.network_interfaces && record.gateway.network_interfaces.length >0'
                 class='editable-row-a'
                 @click="toMonitoring(record.gateway.network_interfaces,record.id,record.gateway.id)">监控信息</a> -->
            </div>
          </template>

          <template v-else-if="column.dataIndex === 'router'">
            <a @click=toWeb(record.web)
               class='editable-row-a'>{{record.router}}</a>
          </template>
        </template>
        <template #title>

        </template>
      </a-table>

      <div class='footer-pagination'>
        <div class='botton-style'
             @click='previous'
             :style="pagination.current > 1 ? { color: 'white' } : { color: '#999' }">上一页</div>
        <div class='pagination-text'>当前第 {{pagination.current}} 页 / 共 {{pagination.allPage}} 页 </div>
        <div class='botton-style'
             @click='nextPage'
             :style="pagination.current < pagination.allPage ? { color: 'white' } : { color: '#999' }">下一页</div>
      </div>

    </div>
  </div>
</template>
<script setup>
import { reactive, ref, computed, onMounted, onBeforeUpdate, onUnmounted, watch, onBeforeUnmount } from "vue";

import { message } from "ant-design-vue";
import dayjs from "dayjs";
import { useRouter, useRoute } from 'vue-router'
import { format } from "echarts";
import zhCN from 'ant-design-vue/es/locale/zh_CN';

import TableComponent from '@/components/TableComponent.vue'
import serviceAxios from "@/utils/serviceAxios";
import { commonFun } from "@/utils/commonFun";
// import { storeAndGateway } from '@/store'
import interfaces from "@/config";
import { checkTree } from "@/utils/regionTree";
import check from "@/utils/checkLocalStorage";
import { decimalUtils } from "@/utils/decimalUtils";
import { selector } from '@/utils/selector'
const locale = zhCN;
const route = useRoute();
const router = useRouter();

const customer_id = localStorage.getItem('customer_id')

const storeId = route.query.storeId

const defaultDate = ref(dayjs()); // 使用moment获取当前日期时间作为默认面板日期
console.log("defaultDate", defaultDate)
onMounted(async () => {
  // 假设你存储用户信息的键是'user'  
  // const localMessage = check.checkData();
  // console.log("localMessage", localMessage)
  // // 检查用户信息是否存在  
  // if (!localMessage) {
  //   // 如果不存在，则跳转到登录页面  
  //   router.push('/loginView');
  // }

});
let timer = null;
onMounted(() => {
  timer = setInterval(queryPage
    , 30000);
})
onBeforeUnmount(() => {
  clearInterval(timer);
});
dayjs.locale("zh-cn");

//按钮的显示
let buttonShow = reactive({
  contractAdd: true,
  contractDelete: true,
  contractEdit: true,
  contractPage: true,
})

const getButtonRules = () => {
  if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
    let rules = JSON.parse(localStorage.getItem("rules"))
    rules = rules[route.fullPath]
    if (rules != undefined) {
      for (let key in buttonShow) {
        buttonShow[key] = rules.includes(interfaces[key]) ? true : false
      }
    }
    else {
      for (let key in buttonShow) {
        buttonShow[key] = false
      }
    }

  }
}







//分页模型
const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 12, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["16", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
  allPage: computed(() => {
    return Math.ceil(pagination.total / pagination.pageSize);
  })
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;

  pagination.current = current;
  pagination.pageSize = pageSize;
  queryFields.page_num = current;
  queryFields.page_size = pageSize;
  queryPage();

};

const formRef = ref(); //表单
const formRefDynamic = ref(); //表单

let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});



const ne = reactive({
  x: "",
  y: ''
})





let showModal = reactive({
  show: false,
  advancedSearch: false,
  storeName: false,
  buttonShow: localStorage.getItem("name") == 'root' ? true : false, //控制删除和编辑按钮的显示
  message: false,
  isDeleteModalVisible: false,
  // test: true
});




//列名字段
const columns = [
  { title: "门店名称", dataIndex: "store_name", key: "store_name", ellipsis: true },
  { title: "别名", dataIndex: "alias", key: "alias", ellipsis: true },

  {
    title: "警报数量",
    dataIndex: "offline_alert_count",
    key: "offline_alert_count",
    ellipsis: true,
  },

  { title: "备注", dataIndex: "desc", key: "desc", ellipsis: true },

  {
    title: "创建时间",
    dataIndex: "create_time",
    key: "create_time",
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "operation",
    key: "operation",
    width: 200,
  },
];


//table数据源
let dataSource = reactive({
  data: [],


});



let searchData = reactive({

  region: null,
  keyword: '',
  store_id: null,
});






//页面筛选
const sift = () => {

  queryFields = {
    region_ids: searchData.region,
    store_id: searchData.store_id,
    keyword: searchData.keyword,
    page_num: pagination.current,
    page_size: pagination.pageSize,
  };
  queryPage();
  message.info('搜索结束')
};

//重置筛选
const resetSift = () => {
  pagination.current = 1;
  pagination.pageSize = 12;
  let data = {
    page_num: pagination.current,
    page_size: pagination.pageSize,
  };
  queryFields = data;
  queryPage();
  for (let key in searchData) {
    if (!['buttonValue', 'searchViewFlag'].includes(key)) {
      searchData[key] = null;

    }
  }
  message.info('重置搜索信息成功')
};

let options = reactive({
  store_id: []
});






const filter = (inputValue, path) => {
  return path.some(
    (option) =>
      option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  );
};


let queryFields = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
});

const queryPage = () => {
  // queryFields['customer_id'] = customer_id
  if (storeId) {
    queryFields['store_id'] = Number(storeId)
    searchData.store_id = Number(storeId)
  }

  queryFields['time_slice'] = '月'
  // queryFields['get_last_data'] = 1
  serviceAxios({
    url: '/v1/iot/electricity_sensor/page',
    timeout: 30000,
    method: "get",
    params: queryFields,
  })
    .then((res) => {
      if (res.data.message = 'success') {
        pagination.total = res.data.data.total;
        let data = [];
        res.data.data.list.forEach((item) => {
          data.push({
            id: item.id,
            key: item.id,
            alias: item.alias,
            desc: item.desc,
            customer_id: item.customer_id,
            customer_name: item.customer_name,
            store_id: item.store_id,
            store_name: item.store_name,
            offline_alert_count: item.offline_alert_count,
            create_time: item.create_time,
            item: item
          });
        });
        dataSource.data = data
      }
      else {
        message.error(res.data.message)
      }

    })
    .catch((err) => {
      console.log(err);
      message.error(res.data.detils);
    });
};


queryPage();


const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};


const toMonitoring = (networkInterfaces, storeId, gatewayId) => {
  let ids = []
  networkInterfaces.forEach(item => {
    ids.push(item.id)
  })
  ids = ids.join(',')
  // storeAndGateway.value.ids = ids
  // storeAndGateway.value.storeId = storeId

  router.push({

    path: "/storeDetailPage",
    query: { ids: ids, storeId: storeId, gatewayId: gatewayId }

  })
}

const toSensorDetails = (record) => {
  router.push({ path: "/energyDetailPage", query: { sensorId: record.id, storeId: record.store_id } })
}

const toLog = (record) => {
  router.push({ path: "/sensorLogList", query: { store_id: record.store_id } })
}

const checkOline = (ids) => {
  serviceAxios({
    url: interfaces.onlineStatus,
    method: 'post',
    data: {
      gateway_ids: ids
    }
  }).then(result => {
    if (result.data.message == 'success') {
      let enableGateList = dataSource.data.filter(item => item.gatewayId != null)
      result.data.data.forEach(
        item => {
          let gatewayStatus = enableGateList.filter(gateway => gateway.gatewayId == item.eth_number)
          gatewayStatus.forEach(i => {
            i.operationalStatus = item.value == '正常' ? true : false
          })
        }
      )
    }
    else {
      message.error(result.data.message)
    }
  }).catch(error => {
    console.log(error)
    message.error('请求异常')
  })
}

//路由管理跳转
const toWeb = (web) => {
  window.open(web, '_blank');
}

const back = () => {
  router.push({ path: '/homePageData' })
}

//向上翻页
const previous = () => {
  if (pagination.current > 1) {
    pagination.current -= 1
    queryFields.page_num = pagination.current
    queryPage()
  } else {

  }

}
//向下翻页
const nextPage = () => {
  if (pagination.current < pagination.allPage) {
    pagination.current += 1
    queryFields.page_num = pagination.current
    queryPage()
  }
  else {

  }
}

(async () => {
  options.store_id = await selector.store()
})()
</script>
<style lang="less" scoped>
@import "@/assets/css/tablePage.css";
@import "@/assets/css/antTable.css";
@import "@/assets/css/pageTitle.css";
</style>