import { ref, reactive } from 'vue'



const websocketData = ref("123")

//用于租户系统 门店列表和监控信息之间的通信数据
const storeAndGateway = ref({
  storeId: '',
  ids: []//硬件接口id
})

export { storeAndGateway }