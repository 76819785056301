// useScreenSize.js
import { ref, onMounted, onUnmounted } from 'vue';

export function useScreenSize () {
  const windowWidth = ref(0);
  const windowHeight = ref(0);

  const updateWindowSize = () => {
    windowWidth.value = window.innerWidth;
    windowHeight.value = window.innerHeight;
  };

  onMounted(() => {
    // 初始化屏幕尺寸
    updateWindowSize();
    // 添加 resize 事件监听器
    window.addEventListener('resize', updateWindowSize);
  });

  onUnmounted(() => {
    // 移除 resize 事件监听器，防止内存泄漏
    window.removeEventListener('resize', updateWindowSize);
  });

  return {
    windowWidth,
    windowHeight
  };
}