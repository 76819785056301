<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template >
  <div class='container-box'>
    <div class='store-message-title-box'>
      <div class='title'
           style='display:flex'>网络详情

        <div @click='toBackStoreList'
             style='margin-left:20px;cursor: pointer;'>返回门店列表</div>
      </div>
    </div>
    <div class='up-box'>
      <div class='base-message-box'>
        <div class='message-up-box'>

          <div class="messageShow-box-up">
            <img class='message-image'
                 src='@/static/demo/storeDetail/显示时间.png' />
            <div class='content-box'>
              <div class='content-name'>
                系统运行时间:
              </div>
              <div class='content-value'>
                {{ MonitoringData.systemRunTime }}
              </div>
            </div>
          </div>

          <div class="messageShow-box-up">
            <img class='message-image'
                 src='@/static/demo/storeDetail/内存.png' />
            <div class='content-box'>
              <div class='content-name'>
                内存利用率:
              </div>
              <div class='content-value'>
                {{ MonitoringData.menItem }}
              </div>
            </div>

          </div>
        </div>
        <div class='message-down-box'>
          <div class="messageShow-box-down">
            <img class='message-image'
                 src='@/static/demo/storeDetail/设备.png' />
            <div class='content-box'>
              <div class='content-name'>
                ping检查:
              </div>
              <div class='content-value'>
                {{ MonitoringData.pingCheck }}
              </div>
            </div>
          </div>
        </div>
        <img class='bottom-left-image'
             src='@/static/home/反光栏.png' />
      </div>

      <div class='cardChartContainer'>
        <div class='chart-title-box'>
          <div v-for='item in tabList'
               :key='item.key'
               @click='portTitleClick(item)'
               class='chart-title-item'
               :class="{ 'chart-title-selected': item.key==key }">
            {{item.key}}
          </div>
        </div>
        <div class='echart-box'
             ref="ethFlowChart"></div>

      </div>
    </div>
    <div class='down-box'>

      <a-table :columns="columns"
               :data-source="data"
               :pagination="false"
               class="ant-table-striped"
               style="overflow: hidden; white-space:nowrap;text-overflow: ellipsis">
      </a-table>

    </div>
  </div>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import { ref, onMounted, onUnmounted, watch, reactive, markRaw } from 'vue';
import * as echarts from 'echarts';
import { message } from "ant-design-vue";
import serviceAxios from '@/utils/serviceAxios';
import interfaces from '@/config';
// import websocket from "@/utils/webSocket";
// import { storeAndGateway } from '@/store'
import { useRouter, useRoute } from 'vue-router'
const route = useRoute();
const router = useRouter();
// console.log('storeAndGateway', storeAndGateway.value)
// if (storeAndGateway.value.storeId == '') {
//   router.push('/customerView')
// }



// websocket.init_websocket()
const MonitoringData = reactive({
  systemRunTime: "", //系统运行时间
  menItem: '',//内存利用率
  pingCheck: "",//ping检查
  totalMemory: "", //总内存
  useMemory: "", //使用内存

  deviceConnectivityChecking: "",//设备连通性检测
  eth0InterfaceStatus: "", //eth0接口状态
  memoryUtilization: [],//内存利用率
  memoryUtilizationDate: [],//内存利用率时间
  pingDelay: [],//ping响应延时
  pingLoss: [],//ping丢包

  Port0UploadsTraffic: [], //端口0上传流量
  Port1UploadsTraffic: [], //端口1上传流量
  Port2UploadsTraffic: [], //端口2上传流量
  Port3UploadsTraffic: [], //端口3上传流量

  uploadInterfaceIte1: [], //Interface lte1): 上传流量
  uploadInterfaceIte2: [], //Interface lte2): 上传流量
  uploadInterfacebridge1: [], //Interface bridge1): 上传流量
  uploadInterfacepptp1: [], //Interface pptp-out1): 上传流量


  Port0UpdownloadsTraffic: [], //端口0下载流量
  Port1UpdownloadsTraffic: [], //端口1下载流量
  Port2UpdownloadsTraffic: [], //端口2下载流量
  Port3UpdownloadsTraffic: [], //端口3下载流量


  downloadInterfaceIte1: [], //Interface lte1): 下载流量
  downloadInterfaceIte2: [], //Interface lte2): 下载流量
  downloadInterfacebridge1: [], //Interface bridge1): 下载流量
  downloadInterfacepptp1: [], //Interface pptp-out1): 下载流量



})


const columns = [
  { title: '时间', dataIndex: 'alertTime', key: 'alertTime' },
  { title: '门店', dataIndex: 'storeName', key: 'storeName' },
  { title: '告警信息', dataIndex: 'alertMessage', key: 'alertMessage' },
  { title: '当前状态', dataIndex: 'alertStatus', key: 'alertStatus' },
  { title: '告警地址', dataIndex: 'alertIp', key: 'alertIp' },
  { title: '持续时长', dataIndex: 'duration', key: 'duration' },

]
const data = ref()
const get_home_data = () => {
  serviceAxios({
    url: interfaces.alertHistories,
    timeout: 30000,
    method: "get",
    params: {
      store_id: storeAndGateway.value.storeId,
      limit: 20,
    }
  }).then(result => {
    // homeData.forewarning_data = result.data.data
    data.value = result.data.data.map((item) => ({
      alertTime: item.alert_time,
      storeName: item.host_name,
      alertStatus: item.message.alert_type,
      alertIp: item.host_ip,
      // alertLevel: item.message['告警等级'],
      alertMessage: item.message['Problem name'],
      duration: item.message['Problem duration']
      // alertData: item.message['监控取值'],
    }))
  }).catch(error => {
    console.log(error)
    // message.error('请求异常，请联系管理员')
  })
}

// get_home_data()


const ethFlowChart = ref(null);





const ethFlowChartOption = {
  grid: {
    top: '20%', // 顶部边距
    bottom: '0%', // 底部边距
    left: '5%', // 左侧边距
    right: '5%', // 右侧边距
    containLabel: true // 是否包含坐标轴的标签
  },
  xAxis: {
    type: 'time',
    boundaryGap: false,
    axisLabel: {
      interval: 15,
      textStyle: { // 设置x轴标签的字体颜色
        fontWeight: 400,
        fontSize: 16,
        color: '#82B9EC',
      }
    }
  },
  yAxis: {
    type: 'value',
    name: "(KB/s)",
    axisLine: { // 设置y轴线条颜色
      lineStyle: {
        color: '#82B9EC',
      }
    },
    axisLabel: { // 设置y轴标签的字体颜色

      textStyle: {
        fontWeight: 'normal',
        fontSize: 16,
        color: '#5FC8F0',
      }
    }
  },
  legend: {
    data: [],
    textStyle: { // 设置图例的字体颜色
      color: '#82B9EC', // 您可以选择任何颜色
    }
  },
  series: [
    {
      type: 'line',
      smooth: true,
      lineStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            { offset: 0, color: '#66ebd7' }, // 渐变起始颜色
            { offset: 1, color: '#00EB9F' }  // 渐变更终颜色
          ],
          globalCoord: false
        }
      },
      areaStyle: { // 添加区域样式
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            { offset: 0, color: '#66ebd7' }, // 渐变起始颜色
            { offset: 1, color: '#00EB9F' }  // 渐变更终颜色
          ],
          globalCoord: false
        }
      }
    }
  ],
  tooltip: {
    show: true
  }
};





const storeAndGateway = ref({
  ids: [],
  storeId: ''
})
onMounted(() => {
  let ids = route.query.ids.split(',')
  let gatewayId = route.query.gatewayId
  ids = ids.map(Number)
  storeAndGateway.value.ids = ids
  storeAndGateway.value.storeId = route.query.storeId
  ethFlowHistory()
  get_home_data()
  getTimeAndMemory(Number(gatewayId))

});

const getTimeAndMemory = (id) => {
  serviceAxios({
    url: interfaces.gatewayTimeAndMemory,
    method: 'get',
    params: {
      gateway_id: id
    }
  }).then(result => {
    if (result.data.message == 'success') {
      const data = result.data.data
      MonitoringData.systemRunTime = data.runtime_item_id.value,
        MonitoringData.menItem = data.mem_item_id.value,
        MonitoringData.pingCheck = data.ping_item_id.value
    }
  }).catch()
}
let legend = []


const windowWidth = ref(0);
const windowHeight = ref(0);




const key = ref();
const tabList = ref([

])

//流量端口key的点击事件
const portTitleClick = (item) => {
  let value = item.key
  key.value = item.key
  ethFlowChartOption.series = []
  ethFlowChartOption.legend.data = []
  for (let key in chartData.value[value]) {
    ethFlowChartOption.legend.data.push(key)
    let seriesData = {
      name: key,
      type: 'line',
      symbol: "none",
      data: [],
    }

    chartData.value[value][key].forEach(element => {
      seriesData.data.push([element.time, element.value])
    });
    ethFlowChartOption.series.push(seriesData)



  }
  console.log('ethFlowChartOption', ethFlowChartOption)
  ethFlowChart.value = markRaw(echarts.init(ethFlowChart.value));
  ethFlowChart.value.setOption(ethFlowChartOption, true)
}

const contentList = {
  tab1: 'content1',
  tab2: 'content2',
};

let chartData = ref()

//获取端口流量数据
const ethFlowHistory = () => {
  serviceAxios({
    url: interfaces.ethFlowHistory,
    method: 'post',
    data: {
      physical_interface_ids: storeAndGateway.value.ids
    }
  }).then(result => {
    if (result.data.message == 'success') {
      chartData.value = result.data.data
      for (let ethKey in result.data.data) {
        console.log("ethKey", ethKey)
        tabList.value.push({
          key: ethKey,
          tab: ethKey,
        })
        for (let ethKeyItem in result.data.data[ethKey]) {
          chartData.value[ethKey][ethKeyItem] = chartData.value[ethKey][ethKeyItem]
        }

      }
      console.log("data.value", chartData.value)


      key.value = tabList.value[0].key

      // ethFlowChartOption.yAxis = {
      //   type: 'value',
      //   name: 'KB/s'
      // }
      ethFlowChartOption.series = []
      ethFlowChartOption.legend.data = []
      for (let key in chartData.value[tabList.value[0].key]) {
        ethFlowChartOption.legend.data.push(key)
        let seriesData = {
          name: key,
          type: 'line',
          symbol: "none",
          data: [],
        }

        chartData.value[tabList.value[0].key][key].forEach(element => {
          seriesData.data.push([element.time, element.value])
        });
        ethFlowChartOption.series.push(seriesData)



      }
      key.value = tabList.value[0].key
      console.log('ethFlowChartOption', ethFlowChartOption)
      ethFlowChart.value = echarts.init(ethFlowChart.value)
      ethFlowChart.value.setOption(ethFlowChartOption, true)

    }
    else {
      message.error(result.data.message)
    }
  }).catch(
    error => {
      console.log(error)
    }
  )
}
// ethFlowHistory()
const chartStyle = ref({
  width: "100%",
  height: "100%"
})


// const chartScreen = () => {
//   const chartContainer = document.getElementById('portCard')
//   console.log('chartContainer', chartContainer)
// }
// chartScreen()

//返回门店列表
const toBackStoreList = () => {
  router.push({ path: '/customerView' })
}

</script>
<style lang="less" scoped>
@import "@/assets/customer/storeDetails.css";
</style>