<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <a-table :pagination="false"
           :scroll="windowSize"
           style=" overflow: hidden !important; white-space: nowrap;  text-overflow: ellipsis;"
           v-bind="$attrs">
    <!-- 转发插槽 -->
    <template v-for="(item, key, i) in slots"
              :key="i"
              v-slot:[key]="{ record, rowIndex, column }">
      <slot :name="key"
            v-bind="{ rowIndex: rowIndex, record: record, column: column }"></slot>
    </template>

    <!-- <template>
      <a-pagination v-model:current="props.current"
                    :total="props.total" />
    </template> -->
  </a-table>

</template>

<script setup>
import { reactive, onMounted, useSlots } from "vue";
import { useScreenSize } from '@/utils/useScreenSize';

const slots = useSlots();

const props = defineProps({
  tableScrollY: {
    type: Number,
    default: 320,
  },
  current: {
    type: Number,
    default: 0,
  },
  total: {
    type: Number,
    default: 0,
  }
});


const { windowWidth, windowHeight } = useScreenSize();
const windowSize = reactive({
  x: "",
  y: ""
})
const tableHeight = () => {
  let height = 0
  let containerName = ['.layout-header', 'ol', '.ant-table-title', '.ant-pagination', '.footer']
  containerName.forEach(item => {
    let con = document.querySelector(item)
    // console.log(item, con)

    if (con != null) {
      height += con.offsetHeight
    }

  });
  windowSize.y = windowHeight.value - height - 80
  // console.log("windowHeight.value", windowHeight.value, height)
}

onMounted(() => {
  tableHeight()
})
window.addEventListener('resize', () => {
  tableHeight()
});
</script>


<style lang='less' scoped>
</style>
