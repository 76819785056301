<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable-next-line vue/multi-word-component-names-->
<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-model-argument -->
<template>

  <div>

    <div style='display:flex;align-items:center'>

    </div>

    <div style="width: 100%; height: 100%;" id="container"></div>

  </div>

</template>

<script setup>


import { onMounted, nextTick, reactive, ref, onBeforeUnmount } from "vue";
import { message } from "ant-design-vue";
import serviceAxios from "@/utils/serviceAxios";
import { commonFun } from "@/utils/commonFun";
import svg from "@/utils/svgData";
import interfaces from "@/config";
import G6 from '@antv/g6';
import { useScreenSize } from '@/utils/useScreenSize';

import { storeMessage } from '@/utils/commonData'

import { useRouter, useRoute } from 'vue-router';
const router = useRouter();
const route = useRoute();
//节点数据
let deviceMessage = [
  {
    name: '无线接入控制器(ac)',
    type: 'ac',
    path: 'ap'
  },
  {
    name: '无线接入点(ap)',
    type: 'ap',
    path: 'ap'
  },
  {
    name: '台式机',
    type: 'pc',
    path: 'pc'
  },
  {
    name: '服务器',
    type: 'server',
    path: '服务器'
  },
  {
    name: '数据库服务器',
    type: 'db_server',
    path: '数据库服务器'
  },

  {
    name: '磁盘录像机',
    type: 'dvr',
    path: '磁盘录像机'
  },
  {
    name: '媒体服务器',
    type: 'media_server',
    path: '媒体服务器'
  },
  {
    name: '播放控制器',
    type: 'play_controller',
    path: '播放控制器'
  },
  {
    name: '视频分路器',
    type: 'video_splitter',
    path: '视频分路器'
  },
  {
    name: '视频拼接器',
    type: 'video_splicer',
    path: '视频拼接器'
  },
  {
    name: 'led控制器',
    type: 'led_controller',
    path: 'LED控制器'
  },
  {
    name: 'led播放器',
    type: 'led_player',
    path: 'LED播放器'
  },
  {
    name: 'lcd播放器',
    type: 'lcd_player',
    path: 'lcd'
  },
  {
    name: '防火墙',
    type: 'firewall',
    path: '防火墙'
  },
  {
    name: '传感器',
    type: 'sensor',
    path: '传感器'
  },
  {
    name: '其他',
    type: 'other',
    path: '其他'
  },
]
const { windowWidth, windowHeight } = useScreenSize();
const deviceRef = ref(null) //添加设备表单
const modalShow = ref(null)
const baseData = reactive({
  zabbix_host: null,
  contact: null,
  name: null,
  size: [],
  storeId: '',
})
let graphInstance = ''
const otherData = reactive({
  host_name: ""
})
const headers = ref({
  Authorization: ""
})
let devices = reactive({
  id: "",
  type: "",
  size: "",
  label: "",
  ip: "",
  offline_threshold: "",
  // cluster: "",
})
const options = reactive({
  zabbix_host: [],
  contract: [],
  type: [

  ],

})

//端口默认数据
let interfacePorts = ref([
  { id: 'port2', x: -15, y: 7, status: true },
  { id: 'port3', x: -5, y: 7, status: true },
  { id: 'port4', x: 5, y: 7, status: true },
  { id: 'port5', x: 15, y: 7, status: true },
  { id: 'port0', x: -35, y: 7, status: true },
  { id: 'port6', x: 25, y: 7, status: true },
  { id: 'port1', x: -25, y: 7, status: true },
  { id: 'port7', x: 35, y: 7, status: true },
])




let data = {
  id: 'root1',
  label: '因特网',
  type: "internet",
  children: [{
    id: 'root',
    label: '网关',
    type: "gateway",
    size: [150, 40],
    ports: [
      { id: 'port2', x: -15, y: 7, status: true },
      { id: 'port3', x: -5, y: 7, status: true },
      { id: 'port4', x: 5, y: 7, status: true },
      { id: 'port5', x: 15, y: 7, status: true },
      { id: 'port0', x: -35, y: 7, status: true },
      { id: 'port6', x: 25, y: 7, status: true },
      { id: 'port1', x: -25, y: 7, status: true },
      { id: 'port7', x: 35, y: 7, status: true },
    ],
    children: [
      {
        id: 'c1',
        label: 'c1',
        type: 'router',
        children: [
          {
            id: 'c1-1',
            label: 'c1-1',
            type: "camera",
          },
          {
            id: 'c1-4',
            label: 'c1-4',
            type: "printer",
          },
          {
            id: 'c1-2',
            label: 'c1-2',
            type: "laptop",
            children: [
              {
                id: 'c1-2-1',
                label: 'c1-2-1',
              },
              {
                id: 'c1-2-2',
                label: 'c1-2-2',
              },
            ],
          },
        ],
      },
      {
        id: 'c2',
        label: 'c2',
        type: 'switch'
      },
      {
        id: 'c3',
        label: 'c3',
        children: [
          {
            id: 'c3-1',
            label: 'c3-1',
          },
          {
            id: 'c3-2',
            label: 'c3-2',
            children: [
              {
                id: 'c3-2-1',
                label: 'c3-2-1',
              },
              {
                id: 'c3-2-2',
                label: 'c3-2-2',
              },
              {
                id: 'c3-2-3',
                label: 'c3-2-3',
              },
            ],
          },
          {
            id: 'c3-3',
            label: 'c3-3',
          },
        ],
      },
    ],
  }]
};


onMounted(() => {
  const COLLAPSE_ICON = function COLLAPSE_ICON (x, y, r) {
    return [
      ['M', x - r, y - r],
      ['a', r, r, 0, 1, 0, r * 2, 0],
      ['a', r, r, 0, 1, 0, -r * 2, 0],
      ['M', x + 2 - r, y - r],
      ['L', x + r - 2, y - r],
    ];
  };
  const EXPAND_ICON = function EXPAND_ICON (x, y, r) {
    return [
      ['M', x - r, y - r],
      ['a', r, r, 0, 1, 0, r * 2, 0],
      ['a', r, r, 0, 1, 0, -r * 2, 0],
      ['M', x + 2 - r, y - r],
      ['L', x + r - 2, y - r],
      ['M', x, y - 2 * r + 2],
      ['L', x, y - 2],
    ];
  };

  const defaultStateStyles = {
    hover: {
      stroke: '#1890ff',
      lineWidth: 2,
    },
  };

  const defaultNodeStyle = {
    fill: '#91d5ff',
    stroke: '#40a9ff',
    radius: 5,
  };

  const defaultEdgeStyle = {
    stroke: '#91d5ff',
    endArrow: {
      path: 'M 0,0 L 12, 6 L 9,0 L 12, -6 Z',
      fill: '#91d5ff',
      d: -20,
    },
  };

  const defaultLayout = {
    type: 'compactBox',
    direction: 'TB',
    getId: function getId (d) {
      return d.id;
    },
    getHeight: function getHeight () {
      return 16;
    },
    getWidth: function getWidth () {
      return 16;
    },
    getVGap: function getVGap () {
      return 40;
    },
    getHGap: function getHGap () {
      return 70;
    },
  };

  const defaultLabelCfg = {
    style: {
      fill: '#000',
      fontSize: 12,
    },
  };

  deviceMessage.forEach(
    item => {
      G6.registerNode(item.type, {
        draw (cfg, group) {
          const { ports = [] } = cfg;
          cfg.size = [40, 40];
          const styles = this.getShapeStyle(cfg);
          const { labelCfg = {} } = cfg;

          const w = styles.width;
          const h = styles.height;

          const keyShape = group.addShape('rect', {
            attrs: {
              // ...styles,

              width: w,
              height: h + 10,
              x: -w / 2,
              y: -h / 2,
            },
          });

          /**
           * leftIcon 格式如下：
           *  {
           *    style: ShapeStyle;
           *    img: ''
           *  }
           */
          //console.log('cfg.leftIcon', cfg.leftIcon, styles, cfg);
          if (cfg.leftIcon) {
            let { style, img } = cfg.leftIcon;

            img = require(`@/assets/glopt/${item.path}.png`)
            group.addShape('rect', {
              attrs: {
                // x: 1 - w / 2,
                // y: 1 - h / 2,
                // width: 48,
                // height: styles.height - 2,
                // fill: '#8c8c8c',
                // ...style,
              },
            });

            group.addShape('image', {
              attrs: {
                x: - w / 2,
                y: - h / 2,
                width: 38,
                height: 38,
                img: img
              },
              // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
              name: 'image-shape',
            });
          }

          // 如果不需要动态增加或删除元素，则不需要 add 这两个 marker


          if (cfg.label) {
            group.addShape('text', {
              attrs: {
                ...labelCfg.style,
                text: cfg.label,
                x: 50 - w / 2,
                y: 25 - h / 2,
              },
            });
          }
          ports.forEach((portPos, index) => {
            const portRadius = 5;
            const portColor = portPos.status == true ? '#05D57B' : '#58626b'; // 示例颜色交替
            group.addShape('circle', {
              attrs: {
                x: portPos.x,
                y: portPos.y,
                r: portRadius,
                fill: portColor,
                stroke: '#fff',
                lineWidth: 1,
              },
              name: `port-${index}`, // 为端口命名，便于后续交互处理
            });
          });

          return keyShape;
        },
        update: undefined,
      },
        'rect',
      );



    }
  )

  G6.registerNode('gateway', {
    draw (cfg, group) {
      const { ports = [] } = cfg;
      cfg.size = [150, 40];
      const styles = this.getShapeStyle(cfg);
      const { labelCfg = {} } = cfg;

      const w = styles.width;
      const h = styles.height;

      const keyShape = group.addShape('rect', {
        attrs: {
          // ...styles,

          width: w,
          height: h + 10,
          x: -w / 2,
          y: -h / 2,
        },
      });

      /**
       * leftIcon 格式如下：
       *  {
       *    style: ShapeStyle;
       *    img: ''
       *  }
       */
      //console.log('cfg.leftIcon', cfg.leftIcon, styles, cfg);
      if (cfg.leftIcon) {
        let { style, img } = cfg.leftIcon;
        img = svg.svgData.echangeRuijieImg
        group.addShape('rect', {
          attrs: {
            // x: 1 - w / 2,
            // y: 1 - h / 2,
            // width: 48,
            // height: styles.height - 2,
            // fill: '#8c8c8c',
            // ...style,
          },
        });

        group.addShape('image', {
          attrs: {
            x: - w / 2,
            y: - h / 2,
            width: 148,
            height: 38,
            img: img
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: 'image-shape',
        });
      }

      // 如果不需要动态增加或删除元素，则不需要 add 这两个 marker


      if (cfg.label) {
        group.addShape('text', {
          attrs: {
            ...labelCfg.style,
            text: cfg.label,
            x: 160 - w / 2,
            y: 25 - h / 2,
          },
        });
      }
      ports.forEach((portPos, index) => {
        const portRadius = 5;
        const portColor = portPos.status == true ? '#05D57B' : '#58626b'; // 示例颜色交替
        group.addShape('circle', {
          attrs: {
            x: portPos.x,
            y: portPos.y,
            r: portRadius,
            fill: portColor,
            stroke: '#fff',
            lineWidth: 1,
          },
          name: `port-${index}`, // 为端口命名，便于后续交互处理
        });
      });

      return keyShape;
    },
    update: undefined,
  },
    'rect',
  );

  G6.registerNode(
    'internet',
    {
      options: {
        size: [80, 80],
        stroke: '#91d5ff',
        fill: '#91d5ff',
      },
      draw (cfg, group) {
        cfg.size = [50, 50];
        const styles = this.getShapeStyle(cfg);
        const { labelCfg = {} } = cfg;

        const w = styles.width;
        const h = styles.height;

        const keyShape = group.addShape('rect', {
          attrs: {
            ...styles,
            x: -w / 2,
            y: -h / 2,
          },
        });

        /**
         * leftIcon 格式如下：
         *  {
         *    style: ShapeStyle;
         *    img: ''
         *  }
         */
        //console.log('cfg.leftIcon', cfg.leftIcon, styles, cfg);
        if (cfg.leftIcon) {
          let { style, img } = cfg.leftIcon;
          img = svg.svgData.diqiuImg
          // img = ap
          group.addShape('rect', {
            attrs: {
              x: 1 - w / 2,
              y: 1 - h / 2,
              width: 48,
              height: styles.height - 2,
              fill: '#8c8c8c',
              ...style,
            },
          });

          group.addShape('image', {
            attrs: {
              x: - w / 2,
              y: - h / 2,
              width: 50,
              height: 50,
              img:
                img ||
                'https://g.alicdn.com/cm-design/arms-trace/1.0.155/styles/armsTrace/images/TAIR.png',
            },
            // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
            name: 'image-shape',
          });
        }

        // 如果不需要动态增加或删除元素，则不需要 add 这两个 marker


        if (cfg.label) {
          group.addShape('text', {
            attrs: {
              ...labelCfg.style,
              text: cfg.label,
              x: 60 - w / 2,
              y: 35 - h / 2,
            },
          });
        }

        return keyShape;
      },
      update: undefined,
    },
    'rect',
  );

  G6.registerNode('router', {
    draw (cfg, group) {
      const { ports = [] } = cfg;
      cfg.size = [40, 40];
      const styles = this.getShapeStyle(cfg);
      const { labelCfg = {} } = cfg;

      const w = styles.width;
      const h = styles.height;

      const keyShape = group.addShape('rect', {
        attrs: {
          // ...styles,

          width: w,
          height: h + 10,
          x: -w / 2,
          y: -h / 2,
        },
      });

      /**
       * leftIcon 格式如下：
       *  {
       *    style: ShapeStyle;
       *    img: ''
       *  }
       */
      //console.log('cfg.leftIcon', cfg.leftIcon, styles, cfg);
      if (cfg.leftIcon) {
        let { style, img } = cfg.leftIcon;
        img = svg.svgData.routerImg
        group.addShape('rect', {
          attrs: {
            // x: 1 - w / 2,
            // y: 1 - h / 2,
            // width: 48,
            // height: styles.height - 2,
            // fill: '#8c8c8c',
            // ...style,
          },
        });

        group.addShape('image', {
          attrs: {
            x: - w / 2,
            y: - h / 2,
            width: 38,
            height: 38,
            img: img
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: 'image-shape',
        });
      }

      // 如果不需要动态增加或删除元素，则不需要 add 这两个 marker


      if (cfg.label) {
        group.addShape('text', {
          attrs: {
            ...labelCfg.style,
            text: cfg.label,
            x: 50 - w / 2,
            y: 25 - h / 2,
          },
        });
      }
      ports.forEach((portPos, index) => {
        const portRadius = 5;
        const portColor = portPos.status == true ? '#05D57B' : '#58626b'; // 示例颜色交替
        group.addShape('circle', {
          attrs: {
            x: portPos.x,
            y: portPos.y,
            r: portRadius,
            fill: portColor,
            stroke: '#fff',
            lineWidth: 1,
          },
          name: `port-${index}`, // 为端口命名，便于后续交互处理
        });
      });

      return keyShape;
    },
    update: undefined,
  },
    'rect',
  );

  G6.registerNode('switch', {
    draw (cfg, group) {
      const { ports = [] } = cfg;
      cfg.size = [80, 40];
      const styles = this.getShapeStyle(cfg);
      const { labelCfg = {} } = cfg;

      const w = styles.width;
      const h = styles.height;

      const keyShape = group.addShape('rect', {
        attrs: {
          // ...styles,

          width: w,
          height: h + 10,
          x: -w / 2,
          y: -h / 2,
        },
      });

      /**
       * leftIcon 格式如下：
       *  {
       *    style: ShapeStyle;
       *    img: ''
       *  }
       */
      //console.log('cfg.leftIcon', cfg.leftIcon, styles, cfg);
      if (cfg.leftIcon) {
        let { style, img } = cfg.leftIcon;
        img = svg.svgData.exchangeImg
        group.addShape('rect', {
          attrs: {
            // x: 1 - w / 2,
            // y: 1 - h / 2,
            // width: 48,
            // height: styles.height - 2,
            // fill: '#8c8c8c',
            // ...style,
          },
        });

        group.addShape('image', {
          attrs: {
            x: - w / 2,
            y: - h / 2,
            width: 78,
            height: 38,
            img: img
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: 'image-shape',
        });
      }

      // 如果不需要动态增加或删除元素，则不需要 add 这两个 marker


      if (cfg.label) {
        group.addShape('text', {
          attrs: {
            ...labelCfg.style,
            text: cfg.label,
            x: 80 - w / 2,
            y: 25 - h / 2,
          },
        });
      }
      ports.forEach((portPos, index) => {
        const portRadius = 5;
        const portColor = portPos.status == true ? '#05D57B' : '#58626b'; // 示例颜色交替
        group.addShape('circle', {
          attrs: {
            x: portPos.x,
            y: portPos.y,
            r: portRadius,
            fill: portColor,
            stroke: '#fff',
            lineWidth: 1,
          },
          name: `port-${index}`, // 为端口命名，便于后续交互处理
        });
      });

      return keyShape;
    },
    update: undefined,
  },
    'rect',
  );

  G6.registerNode('camera', {
    draw (cfg, group) {
      const { ports = [] } = cfg;
      cfg.size = [40, 40];
      const styles = this.getShapeStyle(cfg);
      const { labelCfg = {} } = cfg;

      const w = styles.width;
      const h = styles.height;

      const keyShape = group.addShape('rect', {
        attrs: {
          // ...styles,

          width: w,
          height: h + 10,
          x: -w / 2,
          y: -h / 2,
        },
      });

      /**
       * leftIcon 格式如下：
       *  {
       *    style: ShapeStyle;
       *    img: ''
       *  }
       */
      //console.log('cfg.leftIcon', cfg.leftIcon, styles, cfg);
      if (cfg.leftIcon) {
        let { style, img } = cfg.leftIcon;
        img = svg.svgData.cameraImg
        group.addShape('rect', {
          attrs: {
            // x: 1 - w / 2,
            // y: 1 - h / 2,
            // width: 48,
            // height: styles.height - 2,
            // fill: '#8c8c8c',
            // ...style,
          },
        });

        group.addShape('image', {
          attrs: {
            x: - w / 2,
            y: - h / 2,
            width: 38,
            height: 38,
            img: img
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: 'image-shape',
        });
      }

      // 如果不需要动态增加或删除元素，则不需要 add 这两个 marker


      if (cfg.label) {
        group.addShape('text', {
          attrs: {
            ...labelCfg.style,
            text: cfg.label,
            x: 50 - w / 2,
            y: 25 - h / 2,
          },
        });
      }


      return keyShape;
    },
    update: undefined,
  },
    'rect',
  );

  G6.registerNode('printer', {
    draw (cfg, group) {
      const { ports = [] } = cfg;
      cfg.size = [40, 40];
      const styles = this.getShapeStyle(cfg);
      const { labelCfg = {} } = cfg;

      const w = styles.width;
      const h = styles.height;

      const keyShape = group.addShape('rect', {
        attrs: {
          // ...styles,

          width: w,
          height: h + 10,
          x: -w / 2,
          y: -h / 2,
        },
      });

      /**
       * leftIcon 格式如下：
       *  {
       *    style: ShapeStyle;
       *    img: ''
       *  }
       */
      //console.log('cfg.leftIcon', cfg.leftIcon, styles, cfg);
      if (cfg.leftIcon) {
        let { style, img } = cfg.leftIcon;
        img = svg.svgData.printer
        group.addShape('rect', {
          attrs: {
            // x: 1 - w / 2,
            // y: 1 - h / 2,
            // width: 48,
            // height: styles.height - 2,
            // fill: '#8c8c8c',
            // ...style,
          },
        });

        group.addShape('image', {
          attrs: {
            x: - w / 2,
            y: - h / 2,
            width: 38,
            height: 38,
            img: img
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: 'image-shape',
        });
      }

      // 如果不需要动态增加或删除元素，则不需要 add 这两个 marker


      if (cfg.label) {
        group.addShape('text', {
          attrs: {
            ...labelCfg.style,
            text: cfg.label,
            x: 50 - w / 2,
            y: 25 - h / 2,
          },
        });
      }


      return keyShape;
    },
    update: undefined,
  },
    'rect',
  );

  G6.registerNode('laptop', {
    draw (cfg, group) {
      const { ports = [] } = cfg;
      cfg.size = [40, 40];
      const styles = this.getShapeStyle(cfg);
      const { labelCfg = {} } = cfg;

      const w = styles.width;
      const h = styles.height;

      const keyShape = group.addShape('rect', {
        attrs: {
          // ...styles,

          width: w,
          height: h + 10,
          x: -w / 2,
          y: -h / 2,
        },
      });

      /**
       * leftIcon 格式如下：
       *  {
       *    style: ShapeStyle;
       *    img: ''
       *  }
       */
      //console.log('cfg.leftIcon', cfg.leftIcon, styles, cfg);
      if (cfg.leftIcon) {
        let { style, img } = cfg.leftIcon;
        img = svg.svgData.hostImg
        group.addShape('rect', {
          attrs: {
            // x: 1 - w / 2,
            // y: 1 - h / 2,
            // width: 48,
            // height: styles.height - 2,
            // fill: '#8c8c8c',
            // ...style,
          },
        });

        group.addShape('image', {
          attrs: {
            x: - w / 2,
            y: - h / 2,
            width: 38,
            height: 38,
            img: img
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: 'image-shape',
        });
      }

      // 如果不需要动态增加或删除元素，则不需要 add 这两个 marker

      if (cfg.label) {
        group.addShape('text', {
          attrs: {
            ...labelCfg.style,
            text: cfg.label,
            x: 50 - w / 2,
            y: 25 - h / 2,
          },
        });
      }


      return keyShape;
    },
    update: undefined,
  },
    'rect',
  );

  G6.registerBehavior('double-click-node', {
    getEvents () {
      return {
        'node:dblclick': 'doubleClick',
      };
    },
    doubleClick (ev) {
      // console.log(self, graph)
      const node = ev.item; // 从事件中获取边
      console.log("双击节点", node);
      showNodeInfoInput(node)
    },
  });

  G6.registerEdge('flow-line', {
    draw (cfg, group) {
      const startPoint = cfg.startPoint;
      const endPoint = cfg.endPoint;
      let paths = [
        ['M', startPoint.x, startPoint.y],
        ['L', startPoint.x, (startPoint.y + endPoint.y) / 2],
        ['L', endPoint.x, (startPoint.y + endPoint.y) / 2],
        ['L', endPoint.x, endPoint.y],
      ]
      if (startPoint.x > endPoint.x) {
        paths[0] = ['M', startPoint.x - (startPoint.x - endPoint.x) / 40, startPoint.y]
        paths[1] = ['L', startPoint.x - (startPoint.x - endPoint.x) / 40, ((startPoint.y + endPoint.y) / 2) - (startPoint.x - endPoint.x) / 40]
        paths[2] = ['L', endPoint.x, ((startPoint.y + endPoint.y) / 2) - (startPoint.x - endPoint.x) / 40]

      }
      else if (startPoint.x < endPoint.x) {
        paths[0] = ['M', startPoint.x + (endPoint.x - startPoint.x) / 40, startPoint.y]
        paths[1] = ['L', startPoint.x + (endPoint.x - startPoint.x) / 40, ((startPoint.y + endPoint.y) / 2) - (endPoint.x - startPoint.x) / 40]
        paths[2] = ['L', endPoint.x, ((startPoint.y + endPoint.y) / 2) - (endPoint.x - startPoint.x) / 40]


      }

      const { style } = cfg;
      const shape = group.addShape('path', {
        attrs: {
          stroke: style.stroke,
          // endArrow: style.endArrow,
          path: paths,
        },
      });

      return shape;
    },

    afterDraw (cfg, group) {
      // get the first shape in the group, it is the edge's path here=
      const shape = group.get('children')[0];
      // the start position of the edge's path
      const startPoint = shape.getPoint(0);

      // add red circle shape
      const circle = group.addShape('circle', {
        attrs: {
          x: startPoint.x,
          y: startPoint.y,
          fill: '#1890ff',
          r: 3,
        },
        // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
        name: 'circle-shape',
      });

      // animation for the red circle
      circle.animate(
        (ratio) => {
          // the operations in each frame. Ratio ranges from 0 to 1 indicating the prograss of the animation. Returns the modified configurations
          // get the position on the edge according to the ratio
          const tmpPoint = shape.getPoint(ratio);
          // returns the modified configurations here, x and y here
          return {
            x: tmpPoint.x,
            y: tmpPoint.y,
          };
        },
        {
          repeat: true, // Whether executes the animation repeatly
          duration: 3000, // the duration for executing once
        },
      );
    },
  });

  G6.Util.traverseTree(data, (d) => {
    d.leftIcon = {
      style: {
        fill: '#e6fffb',
        stroke: '#e6fffb',
      },
      img: 'https://gw.alipayobjects.com/mdn/rms_f8c6a0/afts/img/A*Q_FQT6nwEC8AAAAAAAAAAABkARQnAQ',
    };
    return true;
  });

  G6.registerNode(
    'icon-node',
    {
      options: {
        size: [60, 20],
        stroke: '#91d5ff',
        fill: '#91d5ff',
      },
      draw (cfg, group) {
        const styles = this.getShapeStyle(cfg);
        const { labelCfg = {} } = cfg;

        const w = styles.width;
        const h = styles.height;

        const keyShape = group.addShape('rect', {
          attrs: {
            ...styles,
            x: -w / 2,
            y: -h / 2,
          },
        });

        /**
         * leftIcon 格式如下：
         *  {
         *    style: ShapeStyle;
         *    img: ''
         *  }
         */
        // console.log('cfg.leftIcon', cfg.leftIcon);
        if (cfg.leftIcon) {
          const { style, img } = cfg.leftIcon;
          group.addShape('rect', {
            attrs: {
              x: 1 - w / 2,
              y: 1 - h / 2,
              width: 38,
              height: styles.height - 2,
              fill: '#8c8c8c',
              ...style,
            },
          });

          group.addShape('image', {
            attrs: {
              x: 8 - w / 2,
              y: 8 - h / 2,
              width: 24,
              height: 24,
              img:
                img ||
                'https://g.alicdn.com/cm-design/arms-trace/1.0.155/styles/armsTrace/images/TAIR.png',
            },
            // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
            name: 'image-shape',
          });
        }



        if (cfg.label) {
          group.addShape('text', {
            attrs: {
              ...labelCfg.style,
              text: cfg.label,
              x: 50 - w / 2,
              y: 25 - h / 2,
            },
          });
        }

        return keyShape;
      },
      update: undefined,
    },
    'rect',
  );

  const container = document.getElementById('container');
  const width = container.scrollWidth;
  const height = container.scrollHeight;

  const minimap = new G6.Minimap({
    size: [150, 100],
  });
  graphInstance = new G6.TreeGraph({
    container: 'container',
    width,
    height,
    linkCenter: true,
    maxZoom: 1,
    plugins: [minimap],
    modes: {
      default: ['drag-canvas', 'zoom-canvas', 'double-click-node',
      ],
    },
    defaultNode: {
      type: 'icon-node',
      size: [40, 40],
      style: defaultNodeStyle,
      labelCfg: defaultLabelCfg,
    },
    defaultEdge: {
      type: 'flow-line',
      style: defaultEdgeStyle,
    },
    nodeStateStyles: defaultStateStyles,
    edgeStateStyles: defaultStateStyles,
    layout: defaultLayout,
  });

  function resizeGraph () {
    try {

      if (graphInstance) {
        const container = document.getElementById('container');
        console.log("宽高:", container.clientWidth, container.clientHeight, windowHeight.value, container.clientHeight > windowHeight.value - 150)
        graphInstance.changeSize(container.clientWidth, container.clientHeight > windowHeight.value - 150 ? container.clientHeight : windowHeight.value - 150); // 动态设置图表的宽高
        graphInstance.fitView(); // 适应视图，确保所有的节点都在可视范围内
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  // 添加窗口resize事件监听，当窗口大小改变时重新调整图表尺寸
  window.addEventListener('resize', () => {
    resizeGraph();
  });
  resizeGraph()

  // graphInstance.data(data);
  // graphInstance.render();
  // graphInstance.fitView();

  graphInstance.on('node:mouseenter', (evt) => {
    const { item } = evt;
    graphInstance.setItemState(item, 'hover', true);
  });

  graphInstance.on('node:mouseleave', (evt) => {
    const { item } = evt;
    graphInstance.setItemState(item, 'hover', false);
  });

  graphInstance.on('node:click', (evt) => {
    const { item, target } = evt;
    const targetType = target.get('type');
    const name = target.get('name');

    // 增加元素
    if (targetType === 'marker') {
      const model = item.getModel();
      if (name === 'add-item') {
        if (!model.children) {
          model.children = [];
        }
        const id = `n-${Math.random()}`;
        model.children.push({
          id,
          label: id.substr(0, 8),
          leftIcon: {
            style: {
              fill: '#e6fffb',
              stroke: '#e6fffb',
            },
            img:
              'https://gw.alipayobjects.com/mdn/rms_f8c6a0/afts/img/A*Q_FQT6nwEC8AAAAAAAAAAABkARQnAQ',
          },
        });
        graphInstance.updateChild(model, model.id);
      } else if (name === 'remove-item') {
        graphInstance.removeChild(model.id);
      }
    }
  });

  if (typeof window !== 'undefined')
    window.onresize = () => {
      if (!graphInstance || graphInstance.get('destroyed')) return;
      if (!container || !container.scrollWidth || !container.scrollHeight) return;
      graphInstance.changeSize(container.scrollWidth, container.scrollHeight);
    };

});




let nodeItem = ""

function showNodeInfoInput (node) {
  modalShow.value = true
  nodeItem = node
  console.log(node)
  devices.label = node._cfg.model.label
  devices.type = node._cfg.model.type
  devices.ip = node._cfg.model.ip
  devices.offline_threshold = node._cfg.model.offline_threshold
  devices.cluster = node._cfg.model.cluster
  // devices.size = node._cfg.model.size.join("*")
};

const handleOk = () => {
  console.log("devices", devices)
  nodeItem.update({
    label: devices.label,
    type: devices.type,
    ip: devices.ip,
    offline_threshold: devices.offline_threshold,
    cluster: devices.cluster,
    // size: [width, height]
  });
  modalShow.value = false
  // data.nodes.forEach(item =>id {
  //   item = getSvg(item)
  // })
  graphInstance.data(data);
  // graphInstance.render();
  // graphInstance.fitView()

}





const getGplot = (id) => {
  serviceAxios({
    url: '/v1/ops/topological_diagrams/get_by_store_id',
    method: 'get',
    headers: headers.value,
    params: {
      store_id: id
    }
  }).then(result => {
    if (result.data.message == 'success') {
      let gplotData = result.data.data
      console.log('gplotData', gplotData)
      data = gplotData.topological_structure
      baseData.zabbix_host = gplotData.ip
      otherData.host_name = gplotData.ip
      baseData.name = gplotData.name
      // data.nodes.forEach(item => {
      //   item = getSvg(item)
      // })
      getNodeStatus()
      console.log('data', data)
      graphInstance.data(data);
      graphInstance.render();
      graphInstance.fitView();

    }
    else {
      // message.error(result.data.message)
      baseData.zabbix_host = null
      baseData.name = null
      data.children[0].children = []
      console.log('data1111', data)
      graphInstance.read(data);
      getNodeStatus()
      console.log("初始化数据")
      // graphInstance.render(); // 触发重新渲染
      graphInstance.fitView();


    }

    // console.log("所有的节点数据", graphInstance.getNodes())

  }).catch()
}

const hostChange = (value, option) => {
  console.log(value, option)
  otherData.host_name = option.label
  console.log(otherData.host_name)

}

const onContractChange = (value, selectedOptions) => {
  console.log(value, selectedOptions)
  let store_id = value[2]
  serviceAxios({
    url: '/v1/ops/zabbix_host/get_by_store_id',
    method: 'get',
    headers:headers.value,
    params: {
      "store_id": store_id
    }
  }).then(
    result => {
      if (result.data.message == 'success') {
        getInterface(result.data.data.id, store_id)
      }
      else {
        // message.error(result.data.message)
        interfacePorts.value = interfacePorts.value.slice(0, 4)
        data.children[0].ports = interfacePorts.value
        getGplot(store_id)
      }
    }
  )


}



//获取网关的端口数量
const getInterface = (id, store_id) => {
  serviceAxios({
    url: interfaces.interfaceTriggerGetGateway,
    method: 'get',
    headers: headers.value,
    params: {
      gateway_id: Number(id)
    }
  }).then(
    result => {

      if (result.data.message == 'success') {
        let numberFlag = 0
         interfacePorts.value = [
          { id: 'port2', x: -15, y: 7, status: true },
          { id: 'port3', x: -5, y: 7, status: true },
          { id: 'port4', x: 5, y: 7, status: true },
          { id: 'port5', x: 15, y: 7, status: true },


          { id: 'port6', x: 25, y: 7, status: true },
          { id: 'port1', x: -25, y: 7, status: true },
          { id: 'port7', x: 35, y: 7, status: true },
          { id: 'port0', x: -35, y: 7, status: true },
        ]
        let portLength = result.data.data.physical_interfaces_count
        interfacePorts.value = interfacePorts.value.slice(0, portLength)
        console.log('interfacePorts', interfacePorts.value)
        result.data.data.interfaces.forEach(item => {
          if (item.eth_number != null && typeof (item.eth_number) == 'number') {
            interfacePorts.value[numberFlag].id = item.id
            interfacePorts.value[numberFlag].name = item.name
            numberFlag += 1
          }
        })
        data.children[0].ports = interfacePorts.value
        getGplot(store_id)
      }
      else {
        // message.error(result.data.message)
      }


    }
  ).catch(error => {
    console.log(error)
    // message.error("请求异常")
  })
}


const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};



//节点状态
const getNodeStatus = () => {
  let nodeIp = [
  ]
  if (baseData.storeId != null) {
    serviceAxios({
      url:"/v1/ops/topological_diagram/node/status/all",
      method: "get",
      headers:headers.value,
      params: {
        store_id: baseData.storeId
      }
    }).then(
      result => {
        console.log('result', result)
        if (result.data.message == 'success') {
          console.log(result.data)
          nodeIp = result.data.data.lan.ip_list
          let online = result.data.data.online
          const nodesList = graphInstance.getNodes()
          if (nodesList.length > 0) {
            nodesList.forEach(item => {
              const model = item.getModel(); // 获取节点的数据模型
              // console.log("节点数据", model.ip, nodeIp.includes(model.ip));
              if (!['internet', 'gateway'].includes(model.type)) {
                try {
                  if (nodeIp.includes(model.ip)) {
                    model.labelCfg.style.fill = "#222831"
                    graphInstance.updateItem(item, model);
                  }

                  else {
                    model.labelCfg.style.fill = "#e23e57"
                    graphInstance.updateItem(item, model);

                  }
                }
                catch (error) {
                  console.log(error)
                }


              }
              else {
                if (model.type == 'gateway') {
                  console.log('gateway', online == '正常', result.data)
                  model.labelCfg.style.fill = online == '正常' ? "#222831" : "#e23e57"
                }
                else {
                  //  console.log("英特网，网关", model)
                  //因特网
                  model.labelCfg.style.fill = "#222831"

                }
                graphInstance.updateItem(item, model);

              }
              console.log("全节点数据", graphInstance.getNodes())
            })

          }

          //端口

          //getwayModel.
          if (result.data.data.gateway.length > 0) {
            console.log('端口检测')
            let gateway = graphInstance.findById('root');
            let getewayModel = gateway.getModel();
            try {
              result.data.data.gateway.forEach((item, index) => {
                getewayModel.ports[index].status = item.value == "在线" ? true : false
                graphInstance.updateItem(gateway, getewayModel);
              })
            }
            catch (error) {
              console.log(error)
            }
          }

          // graphInstance.render();
          // graphInstance.fitView();
        }
        else {
          clearInterval(timer);
          // message.error(result.data.message)
        }
      }
    ).catch(error => {
      console.log(error)
      clearInterval(timer);
      // message.error("请求错误")
    })

  }

}

let timer = null;
onMounted(() => {
  timer = setInterval(getNodeStatus, 30000);
})
onBeforeUnmount(() => {
  clearInterval(timer);
});
onMounted(() => {
  console.log('route', route, route.query.storeId, route.query.token)
  if (route.query.storeId) {
    let token = route.query.token
    let storeId = Number(route.query.storeId)
    baseData.storeId = storeId
    headers.value.Authorization = 'Bearer ' + token
    console.log("storeId", storeId)
    serviceAxios({
      url: '/v1/ops/zabbix_host/get_by_store_id',
      method: 'get',
      headers: headers.value,
      params: {
        "store_id": storeId
      }
    }).then(
      result => {
        if (result.data.message == 'success') {
          getInterface(result.data.data.id, storeId)
        }
        else {
          // message.error(result.data.message)
          interfacePorts.value = interfacePorts.value.slice(0, 4)
          data.children[0].ports = interfacePorts.value
          getGplot(storeId)
        }
      }
    )
  }

})


//替换键值
function transformKeys (obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj; // 如果不是对象或为null，直接返回
  }

  if (Array.isArray(obj)) {
    return obj.map(transformKeys); // 如果是数组，则递归处理每个元素
  }

  const newObj = {}; // 创建一个新的对象来存放转换后的键值对
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      let newKey = key; // 初始化新键名为原键名

      // 根据键名进行替换
      if (key === 'name') newKey = 'label';
      else if (key === 'id') newKey = 'value';
      else if (key === 'stores' && obj['children'] == undefined) newKey = 'children';

      newObj[newKey] = transformKeys(obj[key]); // 递归处理值，如果值也是对象或数组
    }
  }

  return newObj;
}



const storeGetTree = () => {
  serviceAxios({
    url: '/v1/tenant/store/get_tree',
    headers:headers.value,
    method: 'get'
  }).then(
    result => {
      let data = result.data.data.children
      data = transformKeys(data)

      options.contract = data
    }
  ).catch()
}
// storeGetTree()
</script>