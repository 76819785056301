<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>

  <a-table :bordered='false'
           :columns="fieldAll.columns"
           :data-source="dataSource.data"
           :row-selection="rowSelection"
           :pagination="pagination"
           @change="handleTableChange">
    <!-- style="min-height: 500px; overflow-y: auto;" -->
    <template #bodyCell="{ column, record }">
      <template v-if="['names'].includes(column.dataIndex)">
        <div>
          <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a>
        </div>
      </template>
      <template v-else-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <a class='editable-row-a'
             @click="toRouter">拓扑图</a>
          <a class='editable-row-a'
             @click="toMonitoring">监控</a>
        </div>
      </template>
    </template>

    <template #title>

      <div class='searchDivContainer'>
        <div class='searchDivContainer'
             v-if="searchData.searchViewFlag">
          <table class="searchTable">
            <template v-for="searchField in searchFields"
                      :key="searchField">
              <tr>
                <template v-for="item in searchField"
                          :key="item.key">
                  <td>
                    {{item.title}}
                  </td>
                  <td>
                    <a-select v-if="['account_id',,'invoice_type_id'].includes(item.dataIndex)"
                              :bordered="false"
                              v-model:value="searchData[item.dataIndex]"
                              style="width:200px"
                              :showArrow="false"
                              :placeholder="`请选择${item.title}`"
                              :options="options[item.dataIndex]"
                              @change="handleChangeCustomer"
                              show-search
                              :filter-option="filterOption"></a-select>
                    <a-input-number v-else-if="['min_amount','max_amount'].includes(item.dataIndex)"
                                    style="width:100%"
                                    :bordered="false"
                                    v-model:value="searchData[item.dataIndex]"
                                    :controls="false"
                                    :step="0.01"
                                    :placeholder="`请输入${item.title}`"></a-input-number>
                    <a-tree-select v-else-if="['region_id'].includes(item.dataIndex)"
                                   :bordered="false"
                                   :showArrow="false"
                                   v-model:value="searchData['region_id']"
                                   show-search
                                   style="width: 100%"
                                   :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                   :placeholder="`请选择${item.title}`"
                                   allow-clear
                                   :tree-data="options['region']"
                                   tree-node-filter-prop="label">
                    </a-tree-select>
                    <a-config-provider :locale="locale"
                                       v-else-if="['start_time_time','end_time_time'].includes(item.dataIndex)">
                      <a-date-picker show-time
                                     :bordered=false
                                     v-model:value="searchData[item.dataIndex]"
                                     format="YYYY-MM-DD HH:mm:ss"
                                     :placeholder="['请选择日期', '请选择时间']"
                                     @change="value =>onSearchTimeChange(value,item.dataIndex)">
                      </a-date-picker>
                    </a-config-provider>
                    <a-input v-else
                             :bordered="false"
                             v-model:value="searchData[item.dataIndex]"
                             :placeholder="`请输入${item.title}`"></a-input>
                  </td>

                </template>
              </tr>
            </template>
          </table>
          <a-button style="margin:2px"
                    type="primary"
                    @click="sift">搜索</a-button>
          <a-button style="margin:2px"
                    type="primary"
                    @click="resetSift">重置</a-button>
        </div>

      </div>

    </template>
    <!-- <template #footer>Footer</template> -->
  </a-table>
</template>
  <script setup>
import TableComponent from '@/components/TableComponent.vue'
import serviceAxios from "@/utils/serviceAxios";
import { commonFun } from "@/utils/commonFun";
import { decimalUtils } from "@/utils/decimalUtils";

import { checkTree } from "@/utils/regionTree";
import { message } from "ant-design-vue";
import { onMounted, reactive, ref, watch } from "vue";
import interfaces from "@/config";
import dayjs from "dayjs";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import websocket from "@/utils/webSocket";

import { useRouter, useRoute } from 'vue-router'
const locale = zhCN;
const router = useRouter()
const route = useRoute()
// { title: "门店名称", dataIndex: "name", key: "name" },
//     { title: "系统运行时间", dataIndex: "system_use_time", key: "system_use_time" },
//     { title: "总内存", dataIndex: "total_mer", key: "total_mer" },
//     { title: "使用内存", dataIndex: "use_mer", key: "use_mer" },
//     { title: "ping检查", dataIndex: "ping_check", key: "ping_check" },
//     { title: "设备连通性检测", dataIndex: "device_check", key: "device_check" },
onMounted(() => {
  watch(websocket.socketData, (newValue, oldValue) => {
    try {
      checkItem(newValue)

    }
    catch { }
  })
})


const checkItem = (newValue) => {

  if (newValue['监控项目'] == '系统运行时间') {
    dataSource.data.forEach(item => {
      item.system_use_time = newValue['监控取值']
    })
  }
  else if (newValue['监控项目'] == '总内存') {
    dataSource.data.forEach(item => {

      item.total_mer = newValue['监控取值']
    })
  }
  else if (newValue['监控项目'] == '使用内存') {
    dataSource.data.forEach(item => {

      item.use_mer = newValue['监控取值']
    })
  }
  else if (newValue['监控项目'] == 'ping检查') {
    dataSource.data.forEach(item => {

      item.ping_check = newValue['监控取值']
    })
  }
  else if (newValue['监控项目'] == '设备连通性检测') {
    dataSource.data.forEach(item => {

      item.device_check = newValue['监控取值']
    })
  }


}





const url = {
  add: interfaces.business_cluster_add,
  delete: interfaces.business_cluster_delete,
  edit: interfaces.business_cluster_edit,
  page: interfaces.business_cluster_page,
}


//按钮的显示
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
})



const getButtonRules = () => {
  let route = useRoute()
  if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
    let rules = JSON.parse(localStorage.getItem("rules"))
    rules = rules[route.fullPath]
    if (rules != undefined) {
      for (let key in buttonShow) {
        buttonShow[key] = rules.includes(url[key]) ? true : false
      }
    }
    else {
      for (let key in buttonShow) {
        buttonShow[key] = false
      }
    }

  }
}

// getButtonRules()


const fieldAll = reactive({
  // table列名数据
  columns: [
    { title: "门店名称", dataIndex: "name", key: "name", width: 240 },
    { title: "系统运行时间", dataIndex: "system_use_time", key: "system_use_time" },
    { title: "总内存", dataIndex: "total_mer", key: "total_mer" },
    { title: "使用内存", dataIndex: "use_mer", key: "use_mer" },
    { title: "ping检查", dataIndex: "ping_check", key: "ping_check" },
    { title: "设备连通性检测", dataIndex: "device_check", key: "device_check" },
    { title: "操作", dataIndex: "operation", key: "operation", fixed: "right", width: "120px", ellipsis: true }

  ],

  //弹框字段数据
  modalData: [
    { title: "商圈名", dataIndex: "name", key: "name" },
    { title: "区域", dataIndex: "region_id", key: "region_id" },
    { title: "商圈地址", dataIndex: "address", key: "address" },
    { title: "备注", dataIndex: "desc", key: "desc" },
  ],

  //搜索框字段
  searchFields: [
    { title: "关键字", dataIndex: "keyword", key: "keyword" },
    { title: "区域", dataIndex: "region_id", key: "region_id" },

  ],

})

// 弹框字段的二维数组
const groupFields = commonFun.groupedItems(fieldAll.modalData, 2)

//搜索框字段二位数组
const searchFields = commonFun.groupedItems(fieldAll.searchFields, 3)

//table数据源
const dataSource = reactive({
  //table的基础数据
  data: [],


})



//选择框数据
const options = reactive({
  region_id: [],
});

// 数据模型
const baseData = reactive({
  id: "",
  name: "",
  region_id: "",
  address: "",
  desc: "",
})


//弹框显示
const modalShow = reactive({
  addOrEdit: false,
  isDeleteModalVisible: false,
  searchView: false,
  contractMessage: false, // 添加里面的合同信息
})

let urlFlag = "" //判断是增加数据还是编辑数据


const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = reactive({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
  // keyword: "马记永"
})

const queryPage = () => {
  serviceAxios({
    url: interfaces.customerUserPage,
    method: 'get',
    params: queryField
  }).then(result => {
    if (result.data.message == 'success') {
      pagination.total = result.data.data.total;

      // 更新表格数据
      let data = []

      result.data.data.list.forEach((item) => {

        data.push({
          id: item.id,
          key: item.id,
          name: item.name,

        })
      });

      dataSource.data = data;
      console.log("数据", dataSource.data);
      host_status_histories()
    }
    else {
      message.error(result.data.message)
    }

  }).catch(err => { console.log(err), message.error("获取数据失败，请联系管理员") })
}
queryPage()
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;

    // if (['children_ids', 'customer_names', 'customer_ids', "region"].includes(key)) {
    //   baseData[key] = [];
    // }
  }
  baseData.duration_unit = "年",
    checkedKeys.value = [];
  modalShow.contractMessage = false

};

const formRef = ref()


//编辑数据
const editData = (record) => {
  urlFlag = url.edit
  modalShow.addOrEdit = true;
  for (let key in baseData) {
    baseData[key] = record[key];
  }

};


//增加数据
const addData = () => {
  resetForm();
  modalShow.addOrEdit = true;
  urlFlag = url.add
};



//判断是勾选删除还是点击删除
let deleteFlag = reactive({
  flag: "",
  data: ""
})

//删除数据
const deleteData = (data) => {

  if (typeof (data) == 'number') {
    modalShow.isDeleteModalVisible = true;
    deleteFlag.flag = "single"
    deleteFlag.data = data
  }
  else {
    if (selectedRowKey.value.length === 0) {
      message.error("请至少勾选一条数据");
    } else {
      modalShow.isDeleteModalVisible = true;
      deleteFlag.flag = "much"
    }
  }

};

//确认删除数据
const handleOkDelete = () => {
  // 执行删除操作
  let ids = [];
  if (deleteFlag.flag == 'single') {
    ids = [deleteFlag.data]
  }
  else if (deleteFlag.flag == 'much') {
    rowData.forEach((item) => {
      ids.push(item.id);
    });
  }
  serviceAxios({
    url: url.delete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(
      result => {
        if (result.data.message == 'success') {
          deleteFlag = {
            flag: "",
            data: ""
          }
          queryPage()
          message.success("删除成功");

        }
        else {
          message.error(result.data.message);
        }
      }

    )
    .catch((err) => {
      console.log(err)
      message.error("请求异常，请联系管理员");
    });
  modalShow.isDeleteModalVisible = false;
};



//提交
const submit = async () => {
  let mustInputFields = ['name', 'region_id', 'address']
  let flag = commonFun.checkFields(baseData, mustInputFields)

  // await Promise.all([formRef.value.validate()])
  if (flag == true) {
    let data = {
      id: baseData.id,
      name: baseData.name,
      region_id: baseData.region_id.at(-1),
      address: baseData.address,
      desc: baseData.desc,
    }
    data = commonFun.removeNullValues(data)
    serviceAxios({
      url: urlFlag,
      method: "post",
      data: data
    }).then(result => {
      if (result.data.message == 'success') {
        message.success("成功")

        modalShow.addOrEdit = false
        queryPage()
      }
      else {
        message.info(result.data.message)
      }


    }).catch(err => {
      console.log(err)
      message.error("处理数据发生异常，请联系管理员")
    })
  }
  else {
    message.error("请填写完整信息");
  }
};




const handleChangeSelect = (dataIndex, value) => {

};

//搜索数据
const searchData = reactive({
  keyword: null, //关键字
  region_id: null,


  buttonValue: "打开搜索",
  searchViewFlag: false  //判断是否打开搜索框
})

//打开搜索框
const openSearchView = () => {
  if (searchData.buttonValue == '打开搜索') {
    searchData.buttonValue = '关闭搜索'
    searchData.searchViewFlag = true
  } else {
    searchData.buttonValue = '打开搜索'
    searchData.searchViewFlag = false

  }

}

//搜索
const sift = () => {
  for (let key in searchData) {
    if (!['buttonValue', 'searchViewFlag'].includes(key)) {
      queryField[key] = searchData[key]

    }
  }
  queryField = commonFun.removeNullStringValue(queryField)
  queryPage()
}

//重置搜索
const resetSift = () => {
  queryField = {
    page_num: 1,
    page_size: 10,

  }
  for (let key in searchData) {
    if (!['buttonValue', 'searchViewFlag'].includes(key)) {
      searchData[key] = null
    }
  }
  console.log(searchData)
  queryPage()
}

//region 事件

const expandedKeys = ref(["0-0-0", "0-0-1"]);
const selectedKeys = ref(["0-0-0", "0-0-1"]);
const checkedKeys = ref(["0-0-0", "0-0-1"]);
watch(expandedKeys, () => {
  console.log("expandedKeys", expandedKeys);
});
watch(selectedKeys, () => {
  console.log("selectedKeys", selectedKeys);
});
watch(checkedKeys, () => {
  console.log("checkedKeys", checkedKeys);
});







const toRouter = () => {
  router.push({
    path: "/glopt",
  })
}

const toMonitoring = () => {
  router.push({
    path: "/show",
  })
}

const host_status_histories = () => {
  serviceAxios({
    url: "/v1/ops/zabbix_host_status_histories/get",
    method: "get",
    params: {
      store_id: 81
    }
  }).then(
    result => {
      result.data.data.forEach(item => {
        // console.log("item", item)
        checkItem(item)
      })
    }
  ).catch()
}




  </script>
  <style lang="less">
.searchDivContainer {
  display: flex;
  // align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-top: 0px;
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */

  //   position: fixed;
  // top: 10px; /* 调整此值以设定距离顶部的距离 */
  // right: 10px; /* 调整此值以设定距离右侧的距离 */
  // z-index: 1000; /* 确保按钮位于其他内容之上，根据需要调整 */
}

.addDataButton {
  margin-top: 0px;
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.editable-row-operations {
  // margin-bottom: 8px;
}
.editable-row-a {
  margin: 2px;
  color: #1890ff;
}

.searchTable {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
}
.searchTable tr td {
  width: 150px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.searchTable tr td.column {
  background-color: #eff3f6;
  color: #393c3e;
  width: 11%;
}
</style>