<!-- eslint-disable vue/valid-template-root -->
<template>

</template>

<script setup>
import serviceAxios from '@/utils/serviceAxios'
import { useRouter } from 'vue-router'
const router = useRouter()


const pointShow = () => {
  serviceAxios({
    url: '/v1/info_dis/point_show/get_token',
    method: 'post'
  }).then(result => {
    if (result.data.message = 'success') {
      let pointShowUrl = result.data.data
      window.open(pointShowUrl)

    }
    else {
      message.error(result.data.message)
    }
  }).catch(error => {
    console.log(error)
  })
}
pointShow()


</script>

<style lang='less' scoped>
</style>