import { message } from 'ant-design-vue';
import serviceAxios from '@/utils/serviceAxios';
import interfaces from '@/config';

// 二级门店信息  品牌-门店
export async function storeMessage (has_gateway) {
  //has_gateway :有3个取值 null， 全部，1，配置过网关的， 0没配置过网关的
  console.log('has_gateway', has_gateway)
  let contractData = {};

  // 返回axios调用的Promise
  try {
    const result = await serviceAxios({
      url: '/v1/customer/store/brand_tree',
      method: "get",
      params: {
        // page_num: 1,
        // page_size: 99999,
        has_gateway: has_gateway,
      }
    });
    let data = []
    result.data.data.forEach(item => {
      // let label = item.brand_name
      // let value = item.brand_name
      // let children = item.stores.map(child => ({
      //    label:child.store_name,
      //    value:child.store_id
      // }))

      data.push({
        label: item.brand_name,
        value: item.brand_name,
        children: item.stores.map(child => ({
          label: child.store_name,
          value: child.store_id
        }))
      })
    })


    // let opContract = []
    // for (let key in contractData) {
    //   opContract.push({
    //     label: contractData[key].brand,
    //     value: key,
    //     children: contractData[key].children
    //   }
    //   )
    // }
    // console.log("contractData", opContract);
    return data;
  } catch (err) {
    message.error("请求异常，请联系管理员");
    console.log(err);
    return await Promise.reject({ error: true, message: "请求异常，请联系管理员" });
  }
}